import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { thunk_get_certificacoes } from '../../store/slices/slice_administrador';

// Styling
import "./styles.css";

// Components
import columnsInit from './columns'
import { ModalCadastro, DialogDelete } from './modais'
import DataTable from '../auxComponents/dataTable';

import { GridToolbarContainer } from '@mui/x-data-grid';
import { Button, Skeleton } from '@mui/material';
import { PostAdd } from '@mui/icons-material';

const CertificacoesWrapper = () => {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.administrador.certificacoes_status)
    const data = useSelector(state => state.administrador.certificacoes)

    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    useEffect(() => {
        if (loading == null) {
            dispatch(thunk_get_certificacoes({ tenantId, tokenId }))
        }
    }, [loading]);

    return <Certificacoes data={data} loading={loading} />
}

const Certificacoes = props => {
    const { data, loading } = props

    const empresas = [...new Set(data.map(c => c.empresa))].sort()
    const grupos = [...new Set(data.map(c => c.grupo_relatorio))].sort()

    const [modalCadastroOpen, setModalCadastroOpen] = useState(false)

    // Para os modais de delete e info
    const [certificacaoId, setCertificacaoId] = useState(null)
    const [modalEditOpen, setModalEditOpen] = useState(false)
    const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false)

    const startDeleteFlow = (row) => {
        setCertificacaoId(row.certificacao_id)
        setDialogDeleteOpen(true)
    }

    const showEdit = (row) => {
        setCertificacaoId(row.certificacao_id)
        setModalEditOpen(true)
    }

    const rows = data.map((r, i) => ({ ...r, id: i }))
    const columns = columnsInit(startDeleteFlow, showEdit)
    const editData = data.filter(c => c.certificacao_id == certificacaoId)[0] || {}

    const CustomToolbar = () => {
        return <GridToolbarContainer>
            <Button variant="text" size="small" disabled={false} startIcon={<PostAdd />} onClick={() => setModalCadastroOpen(true)}>
                Adicionar certificação
            </Button>
        </GridToolbarContainer>
    }

    return <>
        <ModalCadastro modalCadastroOpen={modalEditOpen} setModalCadastroOpen={setModalEditOpen}
            grupos={grupos} empresas={empresas} data={editData} mode='post' />

        <ModalCadastro modalCadastroOpen={modalCadastroOpen} setModalCadastroOpen={setModalCadastroOpen}
            grupos={grupos} empresas={empresas} />

        <DialogDelete open={dialogDeleteOpen} setOpen={setDialogDeleteOpen}
            certificacaoId={certificacaoId} />


        {loading == 'done'
            ? <DataTable rows={rows} columns={columns} customToolbar={CustomToolbar}
                defaultSort={[{ field: 'nome', sort: 'asc' }]} />
            : <Skeleton variant='rectangular' width={'100%'} height={500} />
        }

    </>
}

export default CertificacoesWrapper