import React from 'react';

// Styling
import './App.css';

// Routes
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// 'User'
import Dashboard from './components/colabDashboard/dashboard';
import Cronograma from './components/colabCronograma/cronograma';

// 'Gerencial'
import GerencialResumo from './components/gerencialResumo/gerencialResumo';
import GerencialEquipes from './components/gerencialEquipes/gerencialEquipes';
import GerencialRelatorio from './components/gerencialRelatorio/relatorio';

// 'Admin'
import AdminCertificacoes from './components/adminCertificacoes/certificacoes';
import AdminProgramacoes from './components/adminProgramacoes/programacoes';
import ThirdPartyDashboardWrapper from './components/gerencialEquipes/thirdPartyDashboard';

import PageWrapper from './providers/PageWrapper/PageWrapper';
import { useSelector } from 'react-redux';

/* If user is RH type, should not show dashboard as first screen */
const App = () => {
  const userRole = useSelector(state => state.user.claims.nivel)
  const defaultPage = userRole === 'RH'
    ? <PageWrapper title='Relatórios gerenciais'><GerencialRelatorio /></PageWrapper>
    : <PageWrapper title='Minhas certificações'><Dashboard /></PageWrapper>

  return <Router>
    <Routes>
      <Route path="/" element={defaultPage} />
      <Route path="/dashboard" element={defaultPage} />
      <Route path='/cronograma' element={<PageWrapper title='Cronograma'><Cronograma /></PageWrapper>} />

      <Route path='/gerencial' element={<PageWrapper title='Resumo gerencial'><GerencialResumo /></PageWrapper>} />
      <Route path='/equipe' element={<PageWrapper title='Gestão de equipes'><GerencialEquipes /></PageWrapper>} />
      <Route path='/relatorio' element={<PageWrapper title='Relatórios gerenciais'><GerencialRelatorio /></PageWrapper>} />
      <Route path='/equipe/:usuario_id' element={<PageWrapper><ThirdPartyDashboardWrapper /></PageWrapper>} />

      <Route path='/certificacoes' element={<PageWrapper title='Editor de certificações'><AdminCertificacoes /></PageWrapper>} />
      <Route path='/programacoes' element={<PageWrapper title='Editor de programações'><AdminProgramacoes /></PageWrapper>} />
    </Routes>
  </Router>
};

export default App;