import React from 'react';

// Router
import { useNavigate } from "react-router-dom";

// MUI
import { Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import styled from "@emotion/styled";

// Assets
import { useSelector } from 'react-redux';

const TenantLogo = () => {
    const src = useSelector(state => state.tenant.style.logo.lightBg);
    return <img className='pie_logo' src={src} alt='certification-name' />
};

export const CompletionBadge = props => {
    const { icone, percent } = props

    const color = percent >= 1 ? '#03CEA4' : '#AB1C2F'
    const conicGradientSpec = `conic-gradient(from 315deg, #D9D9D9 0.00% ${100 - (percent * 100)}%, ${color} ${100 - (percent * 100)}% 100%)`

    return <>
        <div className="badge_bg">
            <div className="pie_white" />
            <div className="pie_completion" style={{ background: conicGradientSpec }} />
            {icone ? <img className='pie_logo' src={icone} alt='certification-name' /> : <TenantLogo className='pie_logo' />}
        </div>
    </>
}

export const Accordion = styled((props) => (
    <MuiAccordion elevation={0} {...props} />
))(({ theme }) => ({
    borderRadius: `7px`,
    border: 'none',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    }
}));

const UserList = props => {
    const navigate = useNavigate();
    const { data, title } = props;

    return <div className="coluna-usuarios">
        <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary" gutterBottom>
            {title}
        </Typography>
        <Divider />
        <List dense>
            {
                React.Children.toArray(
                    data.map(p => {
                        return <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(`/equipe/${p.usuario_id}`)}>
                                <ListItemText primary={p.usuario_nome} sx={{ fontSize: 14, textAlign: 'center' }} />
                            </ListItemButton>
                        </ListItem>
                    })
                )
            }
        </List>
    </div>
}

export const UserLists = props => {
    const { data } = props;
    const concluidos = data.filter(p => p?.programacao_concluido);
    const pendentes = data.filter(p => !p?.programacao_concluido);

    return <div className="accordion-content">
        <UserList data={concluidos} title='Concluídos' key='1' />
        <UserList data={pendentes} title='Pendente' key='2' />
    </div>
}

export const Summary = props => {
    const { total, icone, data, certificacao } = props

    const concluidos = data.filter(p => p?.programacao_concluido);

    return <div className="resumo-gestor-accordion-summary">
        <CompletionBadge icone={!total && icone} percent={concluidos.length / data.length} />

        <div className="summary-text">

            <Typography variant='cardTitle'>
                {certificacao}
            </Typography>

            <div className="summary-text-lower">

                <Typography variant='cardKPI' className='cardKPI'>
                    {Math.round(concluidos.length / data.length * 100, 0)}%
                </Typography>

                <span className='summary-details'>
                    Concluídos: <strong>{concluidos.length}</strong>  |  Programados: <strong>{data.length}</strong>
                </span>
            </div>
        </div>
        <h3></h3>
    </div>
}