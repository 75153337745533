import { useState } from "react"

// Redux
import { useSelector } from "react-redux"
import { get_filtered_relatorio } from "../../store/slices/slice_administrador";

// Styling
import { useTheme } from "@emotion/react"

// Components
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Skeleton } from "@mui/material";

import DataTable from "../auxComponents/dataTable"
import Select from './newSelect'

// Data
import { relatorio_usuario_cols } from './dataDef'


const RelatorioPorUsuario = props => {
    const { data, loading } = props

    const theme = useTheme()
    const [showConcluido, setShowConcluido] = useState(false)

    const filteredData = useSelector(state => get_filtered_relatorio(state))

    const total_concluidos = filteredData.filter(r => r.programacao_concluido).length || 0
    const total_geral = filteredData.length || 0

    const rows = filteredData.map((r, i) => ({ ...r, id: i }))
    const concl = Math.round(total_concluidos / total_geral * 100)

    const CustomToolbar = () => {
        return <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarExport csvOptions={{ allColumns: true, utf8WithBom: true, delimiter: ';' }} />
            {showConcluido !== undefined &&
                <Button variant="text" size="small" disabled={false}
                    startIcon={showConcluido ? <Visibility /> : <VisibilityOff />}
                    onClick={() => setShowConcluido(!showConcluido)}>

                    {showConcluido ? 'Mostrar tudo' : 'Mostrar apenas concluídos'}

                </Button>
            }
        </GridToolbarContainer>
    }

    const Filters = <>
        {/* <Select data={data} attr='ano' placeholder='Ano' isMulti loading={loading} /> */}
        <Select data={data} attr='quarter' placeholder='Quarter' isMulti loading={loading} />
        <Select data={data} attr='equipe' placeholder='Equipe' isMulti loading={loading} />
        <Select data={data} attr='tipo' placeholder='Tipo da certificação' isMulti loading={loading} />
        <Select data={data} attr='programacao_empresa' placeholder='Grupo da certificação' isMulti loading={loading} />
        <Select data={data} attr='programacao_nome' placeholder='Certificação' isMulti loading={loading} />
        <Select data={data} attr='nivel_usuario' placeholder='Nível de usuário' isMulti loading={loading} />
        <Select data={data} attr='usuario_nome' placeholder='Colaborador' isMulti loading={loading} />
    </>

    const CardComponent = loading == 'done'
        ? <Card>
            <CardContent style={{ backgroundColor: theme.palette.primary.lightgrey }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {total_concluidos} certificados concluídos
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    de {total_geral} programados
                </Typography>
                <Typography variant='h5' component="div">
                    {isNaN(concl) ? 0 : concl}% de conclusão
                </Typography>
            </CardContent >
        </Card >
        : <Skeleton variant='rectangular' width={'100%'} height={90} />

    return <>
        <div className='filters'>
            <div className='selects'>
                {Filters}
            </div>
            <div className='info'>
                {CardComponent}
            </div>
        </div>
        <br />
        <div className='table'>
            {loading == 'done'
                ? <DataTable rows={rows} columns={relatorio_usuario_cols} customToolbar={CustomToolbar} showConcluido={showConcluido} type={0} />
                : <Skeleton variant='rectangular' width={'100%'} height={700} />
            }
        </div>
    </>
}

export default RelatorioPorUsuario