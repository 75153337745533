// Firebase auth
import { getAuth, signInWithPopup, GoogleAuthProvider, browserLocalPersistence, setPersistence, signOut, onAuthStateChanged } from "firebase/auth";
import { getAuthByTenantId, provider } from "../firebase/firebase";
import { dataLayerPush } from "../ga4/dataLayer";

const getProfileInfo = userInfo => {
    return {
        email: userInfo.email
        , photoURL: userInfo.photoURL
        , displayName: userInfo.displayName
        , uid: userInfo.uid
    }
}

export const authenticate = async (tenantId) => {
    try {
        const auth = getAuthByTenantId(tenantId)
        await setPersistence(auth, browserLocalPersistence)
        const result = await signInWithPopup(auth, provider)
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;
        /* const idToken = await result.user.getIdToken(); */
        const idTokenResult = await result.user.getIdTokenResult();

        const idToken = idTokenResult.token;
        const claims = idTokenResult.claims;
        dataLayerPush({event:"login",method:"google"});
        return { ...getProfileInfo(result.user), tokenId: idToken, claims: claims }
    } catch (e) {
        const errorCode = e.code;
        const errorMessage = e.message;
        throw e
    }
}

export const getLoginStatus = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
        return getProfileInfo(user)
    } else {
        return false
    }
}

export const isUserLoggedIn = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        return user ? true : false
    })
}

export const getUserProfile = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user !== null) {
        return user
    }
}

export const logOff = async () => {
    const auth = getAuth();
    await signOut(auth).then(() => {
        console.log('User logged out successfully!')
    }).catch((error) => {
        throw error
    });
}