import { createTheme } from '@mui/material/styles';
import { getTypographyByTenant, getMuiTypographyByTenant } from './tenantTypography';
import { getComponentsByTenant } from './tenantComponents';

let theme = createTheme({});

const getThemeByTenant = tenantConfig => {
    return theme = createTheme(theme, {
        typography: getTypographyByTenant(tenantConfig)
        , components: {
            MuiTypography: getMuiTypographyByTenant(tenantConfig),
            MuiTab: {
                styleOverrides: {
                  root:{
                    "&.Mui-selected": {
                      color: theme.palette.primary.dark,
                    }
                  }
                }
              },
            MuiButton: {
                styleOverrides: {
                  root:{
                    "&.MuiButton-textPrimary": {
                      color: theme.palette.primary.dark,
                    }
                  }
                }
              },
            MuiFormLabel: {
                styleOverrides: {
                  root:{
                    "&.Mui-focused": {
                      color: theme.palette.primary.dark,
                    }
                  }
                }
              },
            ...getComponentsByTenant(tenantConfig),
        }
        , palette: {
            primary: theme.palette.augmentColor({ color: { main: tenantConfig?.style.palette.primaryColor || "#000000", contrastText: tenantConfig?.style.palette.contrastTextColor || "#ffffff" }, name: 'primary' })
            , secondary: theme.palette.augmentColor({ color: { main: "#ffffff", contrastText: "#000000" }, name: 'secondary' })
            , mediumGrey: { main: 'rgb(175, 175, 175)' }
        }
    });
}

export default getThemeByTenant