import React, { useState, useEffect } from 'react';

// Components
import Modal from '../auxComponents/Modal/modal';
import Cadastro_certificado from './cadastro_certificado';
import SeletorAnos from '../auxComponents/selects/years'
import ControlledSelect from '../auxComponents/selects/controlledSelect';
import certTypes from '../certTypes'

// MUI Components
import { Skeleton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Styling
import './styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { thunk_get_resumo } from '../../store/slices/slice_colaborador';

const FirstPartyDashboardWrapper = () => {
    const dispatch = useDispatch()

    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    const usuario = useSelector(state => state.user)
    const data = useSelector(state => state.colaborador.resumo)
    const loading = useSelector(state => state.colaborador.initialized)
    const saving = useSelector(state => state.colaborador.certificado_post)

    const [ano, setAno] = useState(new Date().getFullYear());

    useEffect(() => {
        dispatch(thunk_get_resumo({tenantId, tokenId, ano}))
    }, []);

    useEffect(() => {
        dispatch(thunk_get_resumo({tenantId, tokenId, ano}))
    }, [ano]);

    useEffect(() => {
        saving == 'done' && dispatch(thunk_get_resumo({tenantId, tokenId, ano}))
    }, [saving])

    // For admin view
    const equipes = [...new Set(data.map(d => d.equipe))]
    const [equipe, setEquipe] = useState('')

    const filteredData = usuario.claims.Admin || usuario.claims.Diretor ? data.filter(p => p.equipe == equipe) : data

    useEffect(() => {
        loading == 'done' && setEquipe(equipes[0])
    }, [data]);

    return <>
        {loading == 'done' && saving == 'done'
            ? <div className='dashboard-filters'>
                <SeletorAnos ano={ano} setAno={setAno} />
                {(usuario.claims.Admin || usuario.claims.Diretor) && <ControlledSelect
                    options={equipes} value={equipe} setValue={setEquipe}
                    type={'single'} placeholder='Equipe' clearable={false}
                />}
            </div>
            : <Skeleton variant='rectangular' width={400} height={50} sx={{ marginBottom: '30px' }} />
        }
        <Dashboard data={filteredData} loading={loading} saving={saving} firstParty />
    </>
}

/* Firstparty = true: Colaborador vendo o resumo em 'primeira pessoa'
Firstparty = false: Gestor ou admin vendo o resumo do colab  */
export const Dashboard = (props) => {
    const { data, gestorWarning, firstParty, loading, saving } = props

    const [show, setShow] = useState(false);
    const [CertEdit, setCertEdit] = useState('');
    const userData = useSelector(state => state.user)

    return <>
        {!userData.claims.hasEquipe || userData.claims.nivel == "RH"
            ? <NewUserMesage />
            : <>
                {firstParty == false && gestorWarning}
                <Resumo data={data} setCertEdit={setCertEdit} setShow={setShow} loading={loading} saving={saving} />
                <Modal title="Modal Salvar Certificados" onClose={() => setShow(false)} show={show}>
                    {CertEdit}
                </Modal>
            </>}
    </>
}

const NewUserMesage = () => {
    return <div className='user-message-space'>
        <div className='padding-message-area'>
            <p className='user-message'>Você ainda não possui programação de certificação cadastrada. Solicite ao seu gestor para incluí-lo na equipe. Após isso, realize o login na plataforma novamente</p>
        </div>
    </div>
}

const Resumo = props => {
    const { data, setCertEdit, setShow, loading, saving } = props
    const quarters = [1, 2, 3, 4]
    const cur_quarter = Math.floor(new Date().getMonth() / 3 + 1);

    return <div className='resumo'>
        {quarters.map(quarter => {
            const cards = data.filter(p => p.quarter == quarter)
            const conclusao = cards.filter(c => c.programacao_concluido).length / cards.length * 100
            const conclusaoNumber = Math.trunc(conclusao)

            return loading == 'done' && saving == 'done'
                ? <div className='quarter-meta' key={quarter} id={`Q${quarter}`}>
                    <div className={`quarter-title ${quarter == cur_quarter ? 'current' : ''}`}>
                        <p className='quarter-text'>{`Q${quarter}`}</p>
                        <p>{conclusaoNumber == Infinity || isNaN(conclusaoNumber) ? 0 : conclusaoNumber}%</p>
                    </div>
                    <div className='cardSpace'>
                        {cards.map(p => <CardProgramacao data={p} setCertEdit={setCertEdit} setShow={setShow} key={p.programacao_nome} />)}
                    </div>
                </div>
                : <Skeleton variant='square' width={'80%'} height={150} key={quarter} sx={{ marginBottom: '30px' }} />

        })}
    </div>
}

const CardProgramacao = props => {
    const data = props.data
    const { setCertEdit, setShow } = props
    const concluido = props.data.certificacoes.find(c => c.concluido) || null
    const expirando = props.data.expirando
    const tipo = props.data.tipo

    const onClick = () => {
        setShow(true)
        const Component = <Cadastro_certificado data={data} colab={data.usuario_id} setShow={setShow} />
        setCertEdit(() => Component)
    }

    return <div className={`card_programacao ${concluido ? 'concluido' : 'a_fazer'}`}
        id={data.programacao_nome}
        style={{ backgroundColor: expirando && '#eed202' }}
        onClick={onClick}
    >
        <div className='card-programacao-header'>
            <img className='logo' src={data.certificacoes[0]?.icone} alt='certification-name'></img>
            <div className='info-icon-area'>
                <Tooltip title={tipo + ': ' + certTypes[tipo].tooltip} >
                    {certTypes[tipo].icon}
                </Tooltip>
            </div>
        </div>
        <Tooltip title={data.programacao_nome}>
            <p className='resumo-cert-name'>
                {data.programacao_nome.length > 37 ?
                    `${data.programacao_nome.substring(0, 32)}...` : data.programacao_nome}
            </p>
        </Tooltip>
    </div >
}

export default FirstPartyDashboardWrapper;