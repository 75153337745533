import React, { useContext, useEffect } from 'react';

// Authentication
import { authenticate } from "../../auth/auth";

// Assets and components
import './styles.css'
import GoogleIcon from '@mui/icons-material/Google';
import Button from '@mui/material/Button';

// Store
import { useDispatch } from 'react-redux';
import { setLoginStatus } from '../../store/slices/slice_user';

import {TenantContext} from '../TenantProvider/TenantProvider'

const Login = () => {
    const dispatch = useDispatch()
    const { tenantId, tenantConfig } = useContext(TenantContext)

    const login = async () => {
        try {
            let profileInfo = await authenticate(tenantId)
            dispatch(setLoginStatus(profileInfo))
        } catch (e) {
            throw e
        }
    }

    return <div className='padding-page'>
        <div className='login'>
            <div className='welcome-banner'>
                <div className='text-box'>
                    <img alt='Logotipo' className='welcome-logo' src={tenantConfig?.style.logo.home} />
                    <p className='subtitle'>Faça o login com seu e-mail para continuar.</p>
                </div>
            </div>
            <div className='login-area'>
                <Button onClick={login} startIcon={<GoogleIcon />} variant='contained' color='secondary' >
                    Login com o Google
                </Button>
            </div>
        </div>
    </div>
}

export default Login;