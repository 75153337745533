import { createContext, useEffect } from "react"

//GA4
import { dataLayerPush } from "../../ga4/dataLayer";

// Store
import { useDispatch, useSelector } from "react-redux"
import { thunk_get_tenant } from "../../store/slices/slice_tenant"

// Firebase
import { getDbByTenantId } from "../../firebase/firebase";

// Theming and styling
import { ThemeProvider } from "@emotion/react";
import getThemeByTenant from "../../theming/tenantTheme/tenantTheme";
import { CircularProgress } from "@mui/material";

export const TenantContext = createContext();

const TenantProvider = props => {
    const dispatch = useDispatch();
    const hostname = window.location.hostname;
    const tenantConfig = useSelector(state => state.tenant)
    const tenantId = tenantConfig?.tenantId;
    const db = getDbByTenantId(tenantId);

    useEffect(() => {
        dispatch(thunk_get_tenant(hostname))
    }, [])

    useEffect(() => {
        document.title = tenantConfig?.tenantName ? tenantConfig?.tenantName + " - Certificações" : "Certificações";
        document.documentElement.style.setProperty('--primary-color', tenantConfig?.style?.palette?.primaryColor);
        document.documentElement.style.setProperty('--contrast-text-color', tenantConfig?.style?.palette?.contrastTextColor);
        document.documentElement.style.setProperty('--primary-color-gradient-end', tenantConfig?.style?.palette?.primaryColor + "90");
        document.documentElement.style.setProperty('--contrast-lines-color', tenantConfig?.style?.palette?.contrastTextColor + "90");
        document.documentElement.style.setProperty('--jira-modal-display', tenantConfig?.tenantName?.toLowerCase() === "i-cherry" ? "block" : "none");
        document.querySelector("meta[name='theme-color']").content = tenantConfig?.style?.palette?.primaryColor ? tenantConfig?.style?.palette?.primaryColor : "#A0A0A0";
        if (tenantConfig?.tenantName) {
            dataLayerPush({event:"tenant_info", tenantName:tenantConfig?.tenantName});
        }
    }, [tenantConfig])

    return <TenantContext.Provider value={{ db: db, tenantId, tenantConfig }}>
        {tenantId
            ? <ThemeProvider theme={getThemeByTenant(tenantConfig)}>
                {props.children}
            </ThemeProvider>
            : <TenantLoader />
        }
    </TenantContext.Provider>
}

export const TenantLoader = () => {
    return <div style={{ width: '100dvw', height: '100dvh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress color='grey' />
    </div>
}

export default TenantProvider