import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { thunk_get_relatorio, resetRelatorioFilter, thunk_get_equipes_gestor, setRelatorioFilter } from '../../store/slices/slice_administrador';

// Styling
import './styles.css'

// Components
import { Skeleton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import YearsToggle from '../auxComponents/selects/years';
import RelatorioDetalhado from './relatorio_detalhado'
import RelatorioPorEquipe from './relatorio_equipes'
import RelatorioPorCert from './relatorio_certs'
import RelatorioPorUsuario from './relatorio_usuarios'

const RelatorioWrapper = () => {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.administrador.relatorio_status)
    const loadingUsuarios = useSelector(state => state.administrador.equipes_gestor_status)

    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    const [visao, setVisao] = useState(0)
    const [ano, setAno] = useState(new Date().getFullYear())
    const data = useSelector(state => state.administrador.relatorio)

    useEffect(() => {
        dispatch(resetRelatorioFilter())
    }, [visao]);

    useEffect(() => {
        dispatch(thunk_get_equipes_gestor({tenantId, tokenId}))
        dispatch(thunk_get_relatorio({tenantId, tokenId, ano}))
    }, [ano]);

    useEffect(() => {
        dispatch(setRelatorioFilter({ attr: 'ano', value: [ano] }))
    }, [ano]);

    const relatorios = {
        0: <RelatorioDetalhado data={data} loading={loading} />
        , 1: <RelatorioPorEquipe data={data} loading={loading} />
        , 2: <RelatorioPorCert data={data} loading={loading} />
        , 3: <RelatorioPorUsuario data={data} loading={loading == 'done' && loadingUsuarios == 'done' ? 'done' : 'pending'} />
    }

    return <div className='content'>
        {loading == 'done'
            ? <div style={{ display: 'flex', 'flexDirection': 'row', justifyContent: 'start', width: '100%', gap: '50px' }}>
                <YearsToggle ano={ano} setAno={setAno} />
                <Toggle visao={visao} setVisao={setVisao} />
            </div>
            : <Skeleton variant='rectangular' width={'100%'} height={40} />
        }
        <br style={{ width: '100%', margin: '20px 0px' }} />
        {relatorios[visao]}
    </div >
}

const Toggle = props => {
    const { visao, setVisao } = props

    return <ToggleButtonGroup size='small'
        value={visao} exclusive onChange={(e, v) => setVisao(v)} aria-label="text alignment"
    >
        <ToggleButton value={0} aria-label="Visão detalhada">
            Visão detalhada
        </ToggleButton>
        <ToggleButton value={1} aria-label="Visão por equipes">
            Visão por equipes
        </ToggleButton>
        <ToggleButton value={2} aria-label="Visão por certificações">
            Visão por certificações
        </ToggleButton>
        <ToggleButton value={3} aria-label="Visão por usuários">
            Visão por usuários
        </ToggleButton>
    </ToggleButtonGroup>
}

export default RelatorioWrapper;