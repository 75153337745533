import { createContext, useContext, useEffect } from 'react'

//GA4
import { dataLayerPushUserId } from '../../ga4/dataLayer';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken } from '../../store/slices/slice_user';

// Auth
import { getAuthByTenantId } from '../../firebase/firebase';
import { onIdTokenChanged } from 'firebase/auth';

// Components
import Login from './LoginPage';

// Context
import { TenantContext } from '../TenantProvider/TenantProvider';

export const AuthContext = createContext();

const AuthProvider = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    const { tenantId } = useContext(TenantContext);
    const auth = getAuthByTenantId(tenantId);

    useEffect(() => {
        const unsubscribe = onIdTokenChanged(auth, async (user) => {
            if (user) {
                const token = await user.getIdToken(true);  // 12:17
                dispatch(refreshToken(token));
            };
        });

        return () => unsubscribe();
    }, []);

    if (user?.loggedIn) {
        dataLayerPushUserId(user.email);
    }

    return user?.loggedIn
        ? <AuthContext.Provider value={{ user, token: user.token }}>
            {props.children}
        </AuthContext.Provider>
        : <Login />
}

export default AuthProvider