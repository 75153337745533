import './styles.css'

// Local components
import MenuComponent from '../../components/menu/menu';
import Title from './Title';

const PageWrapper = (props) => {
    return <div className='padding-page'>
        <div className='dashboard'>
            <MenuComponent />
            <div className='content'>
                <div className='header-panel'></div>
                <div className='page-wrapper'>
                    <div className='page'>
                        <Title title={props.title} />
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default PageWrapper