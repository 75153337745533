import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import { defaultDb } from '../../firebase/firebase';

const defaultState = { fetchStatus: null, tenantName: null, tenantId: undefined }
// Firebase auth
const slice = createSlice({
    name: 'tenant',
    initialState: defaultState,
    reducers: {
        setTenant(state, action) {
            return Object.assign(state, { ...action.payload })
        }
        , setLogoff(state) {
            return Object.assign(state, defaultState)
        }
    },
    extraReducers(builder) {
        builder
            .addCase(thunk_get_tenant.rejected, (state) => {
                return Object.assign(state, { fetchStatus: 'rejected', ...defaultState })
            })
            .addCase(thunk_get_tenant.pending, (state) => {
                return Object.assign(state, { fetchStatus: 'pending' })
            })
            .addCase(thunk_get_tenant.fulfilled, (state, action) => {
                return Object.assign(state, { fetchStatus: 'fulfilled', ...action.payload })
            })
    }
})

export const thunk_get_tenant = createAsyncThunk('tenant/thunk_get_tenant', async (hostname) => {
    const ref = query(collection(defaultDb, 'tenants'), where('allowedHosts', 'array-contains', hostname), limit(1))
    const querySnapshot = await getDocs(ref);
    const data = querySnapshot.docs[0].data()
    return data
});

export const { setLoginStatus, setLogoff } = slice.actions

export default slice.reducer