import { Delete, Edit, Info } from "@mui/icons-material"
import { Button } from "@mui/material"


const cols = (startDeleteFlow, showEdit) => [
    {
        field: 'nome'
        , headerName: 'Nome'
        , width: '400'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'empresa'
        , headerName: 'Empresa'
        , width: '200'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'editar'
        , headerName: 'Editar'
        , width: '125'
        , renderCell: params => {
            return <Button variant='contained' size='small'  color='mediumGrey' 
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                onClick={e => showEdit(params.row)} startIcon={<Edit sx={{ color: 'white' }}/>} >
            </Button>
        }
    }
    , {
        field: 'excluir'
        , headerName: 'Excluir'
        , width: 150
        , renderCell: params => {
            return <Button variant='contained' size='small'
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                onClick={e => startDeleteFlow(params.row)} startIcon={<Delete />} >
            </Button>
        }
    }
]

export default cols