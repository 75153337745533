import React, { useEffect } from "react";
import "./jiraModal.css";

import { useSelector } from 'react-redux';

//initialize JQuery on window for Jira
import $ from 'jquery';

const JiraModal = props => {
  const usuario = useSelector(state => state.user)

  useEffect(() => {
    //initialize JQuery on window for Jira
    if (!window.jQuery || !window.$)  {
    window.jQuery = $;
    window.$ = $;
    const jQuery = $;
    //const scriptJira = document.createElement('script');
    //Versão Rodapé
    //scriptJira.src = "https://jira.uhub.biz/plugins/servlet/issueCollectorBootstrap.js?collectorId=196301ab&locale=en_US";
    //Versão Custom
    //scriptJira.src = "https://jira.uhub.biz/plugins/servlet/issueCollectorBootstrap.js?collectorId=fdf86464&locale=en_US";
    //scriptJira.async = true;

    const ParametrosJira = {
      //'triggerFunction': function(showCollectorDialog) {
        //Requires that jQuery is available! 
        //jQuery("#myCustomTrigger").click(function(e) {
        //  e.preventDefault();
        //  showCollectorDialog();
        //);
        //console.log('trigger ok')
      //  window.getJiraModal = function() {
      //    showCollectorDialog();  
      //  }
      //},
      fieldValues: {
        summary : '[Certificações] Ajuda com o sistema',
        description : 'Descreva em detalhes a ajuda que você precisa, caso queira pode anexar capturas de tela também.',
        'customfield_18639' : '94641',
        priority : '2',
        fullname : usuario.displayName,
        email : usuario.email
      }  
    }

    // Requires jQuery!
    try {
      jQuery.ajax({
        url: "https://jira.uhub.biz/plugins/servlet/issueCollectorBootstrap.js?collectorId=196301ab&locale=en_US",
        type: "get",
        cache: true,
        dataType: "script"
      });  
    } catch (error) {
      console.log("Ocorreu um problema ao carregar o plugin do Jira");
    }    

    const wParametros = window.ATL_JQ_PAGE_PROPS;
    window.ATL_JQ_PAGE_PROPS =  {...wParametros, ...ParametrosJira};
  }
  
    return () => {
      //
    }
  }, []);

  return <div id="AjudaModalBase" style={{display: "none"}}>
      
    </div>
};

export default JiraModal;
