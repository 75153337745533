import { Typography } from "@mui/material"
import { useParams } from "react-router-dom"

export const getNomeFromEmail = (email) => {
    return email.split('@')[0].split('.').map(n => n.charAt(0).toUpperCase() + n.slice(1)).join(' ')
}

const Title = (props) => {
    const { title } = props

    // Para as páginas 'third party dashboard' do gestor
    const { usuario_id } = useParams()
    const thirdPartyDashboardTitle = usuario_id ? `Você está visualizando as certificações do usuário: ${getNomeFromEmail(usuario_id)}` : ''

    return <div className="header-title"
        style={{ width: '75%', display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '5px' }}>
        <Typography variant='pageTitle'>
            {title ? title : thirdPartyDashboardTitle}
        </Typography>
        <br />
    </div>
}

export default Title