export const dataLayerPush = async (dataLayer) => {
    try {
        window.dataLayer.push(dataLayer);
    } catch (e) {
        console.log("Falha ao enviar evento ao dataLayer");
    }
}

export const hash = async (string) => {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }

export const dataLayerPushUserId = async (userId) => {
    const userIdHashed = await hash(userId);
    await dataLayerPush({event:"user_id",user_id:userIdHashed})
}