import React, { useState } from 'react'
import PropTypes from 'prop-types';

import dayjs from 'dayjs';

//GA4
import { dataLayerPush } from '../../ga4/dataLayer';

// Redux actions
import { useDispatch, useSelector } from 'react-redux';
import { thunk_post_certificado, thunk_put_certificado, thunk_delete_certificado } from '../../store/slices/slice_colaborador';
import { thunk_post_certificado_colab, thunk_put_certificado_colab, thunk_delete_certificado_colab } from '../../store/slices/slice_gestor';

// MUI components
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button, FormControlLabel, Switch, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function BasicTabs(props) {
    const { Editor, Info } = props
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Cadastrar certificação" {...a11yProps(0)} />
                    <Tab label="Informações da certificação" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {Editor}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {Info}
            </TabPanel>
        </Box>
    );
}

const InputDataExpiracao = props => {
    const { value, setValue } = props

    return <DatePicker id="input_data_expiracao" label="Data de expiração"
        value={value}
        onChange={newDate => setValue(newDate)}
    />
}

const InputCodigo = props => {
    const { value, setValue } = props

    return (
        <TextField id="input_codigo" label="Identificador do certificado"
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    )
}

const InputUrl = props => {
    const { value, setValue } = props

    return <TextField id="input_url" label="URL do certificado"
        value={value} onChangeCapture={event => setValue(event.target.value)}
    />
}

const readExpiryDate = (cert) => {
    if (cert?.data_expiracao?._seconds) {
        return dayjs(cert?.data_expiracao?._seconds * 1000)
    } else if (cert?.data_expiracao) {
        return dayjs(cert?.data_expiracao)
    } else {
        return undefined
    }
}

const Cadastro_certificado = (props) => {
    const dispatch = useDispatch()
    const { data, colab, setShow } = props

    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    const usuario = useSelector(state => state.user)

    const default_certificacao = data.certificacoes.find(c => c.concluido == true)?.certificacao_id || data.certificacoes[0].certificacao_id
    const [activeCertificacaoId, setActiveCertificacaoId] = useState(default_certificacao)

    const url_prova = data.certificacoes[0].url_prova
    const validade = data.certificacoes[0]
    const nome_certificacao = data.programacao_nome

    const activeCertificacao = data.certificacoes.find(c => c.certificacao_id === activeCertificacaoId)
    const concluido = activeCertificacao.concluido

    const changeActiveCertificacaoId = (certificacao_id) => {
        setActiveCertificacaoId(certificacao_id)
        const activeCertificacao = data.certificacoes.find(c => c.certificacao_id === certificacao_id)
        setData_expiracao(activeCertificacao?.data_expiracao?._seconds ? dayjs(activeCertificacao?.data_expiracao?._seconds * 1000) : dayjs().add(1, 'year'))
        setCodigo(activeCertificacao?.codigo_certificado || '')
        setUrl_certificado(activeCertificacao?.url_certificado || '')
    }

    const [data_expiracao, setData_expiracao] = useState(readExpiryDate(activeCertificacao) || dayjs().add(1, 'year'))
    const [codigo_certificado, setCodigo] = useState(activeCertificacao?.codigo_certificado || '')
    const [url_certificado, setUrl_certificado] = useState(activeCertificacao?.url_certificado || '')
    const [renovacao, setRenovacao] = useState(activeCertificacao?.renovacao || false)

    const saveCertification = () => {
        const post_data = {
            certificacao_id: activeCertificacaoId
            , data_expiracao: data_expiracao ? data_expiracao.$d : null
            , codigo_certificado
            , url_certificado
            , ano: data.ano
            , equipe: data.equipe
            , grupo_relatorio: data.certificacoes[0].grupo_relatorio
            , quarter: data.quarter
            , renovacao
        };

        // Se já tiver concluido, post, se não, put
        if (concluido) {
            if (colab !== usuario.email) {
                dispatch(thunk_post_certificado_colab({ data: { ...post_data, certificado_id: activeCertificacao.certificado_id }, user: colab, tokenId, tenantId }))
                .unwrap().then((result) => {dataLayerPush({event: "cert_registered", cert_name: nome_certificacao})})
            } else {
                dispatch(thunk_post_certificado({ data: { ...post_data, certificado_id: activeCertificacao.certificado_id }, tokenId, tenantId }))
                .unwrap().then((result) => {dataLayerPush({event: "cert_registered", cert_name: nome_certificacao})})
            }
        } else {
            if (colab !== usuario.email) {
                dispatch(thunk_put_certificado_colab({ data: post_data, user: colab, tokenId, tenantId }))
                .unwrap().then((result) => {dataLayerPush({event: "cert_registered", cert_name: nome_certificacao})})
            } else {
                dispatch(thunk_put_certificado({ data: post_data, tokenId, tenantId }))
                .unwrap().then((result) => {dataLayerPush({event: "cert_registered", cert_name: nome_certificacao})})
            }
        }
        setShow(false)
    }

    const removeCertificado = () => {
        if (colab !== usuario.email) {
            dispatch(thunk_delete_certificado_colab({ data: activeCertificacao.certificado_id, user: colab, tokenId, tenantId }))
            .unwrap().then((result) => {dataLayerPush({event: "cert_removed", cert_name: nome_certificacao})})
        } else {
            dispatch(thunk_delete_certificado({ certificadoId: activeCertificacao.certificado_id, tokenId, tenantId }))
            .unwrap().then((result) => {dataLayerPush({event: "cert_removed", cert_name: nome_certificacao})})
        }
        setShow(false)
    }

    const Editor = <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <h1 className='ModalTitle'>Inserir dados do certificado</h1>
        <br />
        <Seletor_certificacao data={data} certificacao={activeCertificacaoId} setCertificacao={changeActiveCertificacaoId} />
        <InputDataExpiracao value={data_expiracao} setValue={setData_expiracao} />
        <br />
        <InputCodigo value={codigo_certificado} setValue={setCodigo} />
        <br />
        <InputUrl value={url_certificado} setValue={setUrl_certificado} />
        <br />
        {activeCertificacao?.empresa == 'Meta' ? <FormControlLabel control={<Switch checked={renovacao} onChange={e => setRenovacao(e.target.checked)} size='big' />} label="Renovação" /> : <></>}
        {<div className='btnArea'>
            {concluido &&
                <Tooltip title={'Esta ação faz com que o certificado volte para o status PENDENTE'}>
                    <Button variant='outlined' onClick={() => removeCertificado()}>Remover</Button>
                    {/* <button className='brnRefresh' onClick={() => removeCertificado()}>Remover</button> */}
                </Tooltip>}

            <Button variant='contained' onClick={() => saveCertification()}>Salvar</Button>
            {/* <button className='btnSalvar'>Salvar</button> */}
        </div>}
    </div>

    const Info = <>
        <div>
            <h1 className='ModalTitle'>Informações de sua certificação: {nome_certificacao}</h1>
            <h2 className='h2-info'>Link para certificação:</h2>
            <a href={url_prova} target="_blank" rel="noreferrer" ><p className='link-prova'>{url_prova}</p></a>
            <h2 className='h2-info'>Validade:</h2>
            <p className='validade'>{validade.validade.quantidade} {validade.validade.dimensao}</p>
        </div>
    </>

    return <BasicTabs Editor={Editor} Info={Info} />
}

// Caso a programação seja do tipo 'Alternativas' (anteriormente 'Opcionais'), cria um seletor para o usuário escolher o certificado.
const Seletor_certificacao = (props) => {
    const { data, certificacao, setCertificacao } = props

    /*     const certificado_concluido = data.certificados.find(c => c.concluido)
        if (certificado_concluido) {
            var default_certificado = certificado_concluido.id
        } else {
            var default_certificado = data.certificados[0].id
        }

                setCertificado(default_certificado) */

    const handleChange = certificacao_id => {
        setCertificacao(certificacao_id)
    }

    if (data.tipo == 'Obrigatória' || data.tipo == 'Opcional') {
        return <></>
    } else {
        return <>
            <Select
                id='select_cadastro_certificado'
                label='Selecione o certificado'
                value={certificacao}
                onChange={e => handleChange(e.target.value)}
            >
                {data.certificacoes.map(c => <MenuItem key={c.certificacao_id} value={c.certificacao_id}>{c.nome}</MenuItem>)}
            </Select>
            <br />
        </>
    }
}

export default Cadastro_certificado