// MUI Provider
import { createTheme } from '@mui/material';
import { ptBR as corePtBr } from '@mui/material/locale';
import { ptBR } from '@mui/x-date-pickers';

// MUI theme configuration
const theme = createTheme(
    {
        typography: {
            h1: {
                fontSize: 28,
            },
            h4: {
                fontSize: 42,
            }
            , cardTitle: {
                fontSize: '1.2rem',
                fontWeight: 500,
                fontFamily: 'Roboto'
            }
            , cardKPI: {
                fontSize: '1.8rem',
                fontWeight: 300,
                fontFamily: 'Roboto'
            }
            , pageTitle: {
                fontSize: '1.2rem',
                fontWeight: 500,
                fontFamily: 'Roboto',
                color: 'rgb(120,120,120)'
            }
        }
        , palette: {
            primary: {
                main: '#CF353E'
                , yellow: 'rgba(233, 225, 119, 0.5)'
                , lightgrey: 'rgb(240,240,240)'
            }
            , secondary: { main: '#4285f4' }
            , yellow: { main: 'rgb(238, 210, 2)' }
            , lightGrey: { main: 'rgb(190, 190, 190)' }
            , mediumGrey: { main: 'rgb(175, 175, 175)' }
            , grey: { main: 'rgb(100, 100, 100)' }
            , blue: { main: '#0059B2' }
            , black: { main: '#00000' }
        }
    },
    ptBR, // x-date-pickers translations
    corePtBr // core translations
)

export default theme