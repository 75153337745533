import { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { get_filtered_programacoes, setProgramacoesFilter, thunk_delete_programacao, thunk_get_certificacoes, thunk_get_equipes, thunk_get_programacoes } from '../../store/slices/slice_administrador';

// Components
import { Add } from '@mui/icons-material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Skeleton } from '@mui/material';

import columnsInit from './columns'
import './styles.css'

import SeletorAnos from '../auxComponents/selects/years'
import SeletorQuarter from '../auxComponents/selects/quarters'
import Modal from '../auxComponents/Modal/modal';

import DataTable from '../auxComponents/dataTable'

import Select from '@mui/material/Select';

import CadastroProgramacoes from './cadastroProg'

const ProgramacoesWrapper = () => {
    const dispatch = useDispatch()

    const data = useSelector(state => state.administrador.programacoes)
    const filteredData = useSelector(state => get_filtered_programacoes(state))

    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    const equipes = useSelector(state => state.administrador.equipes)
    const loading = useSelector(state => state.administrador.programacoes_status_geral)

    useEffect(() => {
        dispatch(thunk_get_programacoes({tenantId, tokenId}))
        dispatch(thunk_get_equipes({tenantId, tokenId}))
        dispatch(thunk_get_certificacoes({tenantId, tokenId}))
    }, []);

    useEffect(() => {
        if (loading == 'deleted') {
            dispatch(thunk_get_programacoes({tenantId, tokenId}))
            dispatch(thunk_get_equipes({tenantId, tokenId}))
            dispatch(thunk_get_certificacoes({tenantId, tokenId}))
        }
    }, [loading]);

    return <Programacoes data={data} filteredData={filteredData} equipes={equipes} loading={loading}  />
}

const Programacoes = (props) => {
    const dispatch = useDispatch()
    const { data, filteredData, loading, equipes } = props

    const [ano, setAno] = useState(new Date().getFullYear())
    const [quarter, setQuarter] = useState(Math.floor(new Date().getMonth() / 3 + 1))
    const [modalOpen, setModalOpen] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [programacaoId, setProgramacaoId] = useState(null)

    const rows = filteredData.map((r, i) => ({ ...r, id: i }))

    const setFilterAno = ano => {
        setAno(ano)
        dispatch(setProgramacoesFilter({ attr: 'ano', value: [ano] || [] }))
    }

    const setFilterQuarter = quarter => {
        setQuarter(quarter)
        dispatch(setProgramacoesFilter({ attr: 'quarter', value: [quarter] || [] }))
    }

    const startDeleteFlow = (row) => {
        setProgramacaoId(row.programacao_id)
        setDialogOpen(true)
    }

    const columns = columnsInit(startDeleteFlow)

    const CustomToolbar = () => {
        return <GridToolbarContainer>
            <Button variant="text" size="small" disabled={false} startIcon={<Add />} onClick={() => setModalOpen(true)}>
                Adicionar programação
            </Button>
        </GridToolbarContainer>
    }

    return <>
        <DialogDelete open={dialogOpen} setOpen={setDialogOpen} programacao_id={programacaoId} />
        <Modal title="Modal Cadastro de programação" onClose={() => setModalOpen(false)} show={modalOpen}>
            <CadastroProgramacoes closeModal={() => setModalOpen(false)} />
        </Modal>
        {loading == 'done'
            ? <div className='programacoes-filters'>
                <div className='programacoes-filters-row'>
                    <SeletorAnos ano={ano} setAno={setFilterAno} />
                    <SeletorQuarter quarter={quarter} setQuarter={setFilterQuarter} />
                    <SelectEquipes data={data} attr='equipe' placeholder='Equipe' equipes={equipes} />
                </div>
            </div>
            : <Skeleton variant='rectangular' width={'65%'} height={55} sx={{ marginBottom: '40px' }} />
        }
        {loading == 'done'
            ? <DataTable rows={rows} columns={columns} customToolbar={CustomToolbar} />
            : <Skeleton variant='rectangular' width={'100%'} height={450} />}
    </>
}

const SelectEquipes = (props) => {
    const { equipes } = props

    const dispatch = useDispatch()

    // const data = useSelector(state => state.administrador.programacoes)

    const { attr, placeholder } = props
    const id = `select-${placeholder}`

    const items = equipes.map(e => e.nome).sort()
    const defaultValue = items[0]

    const selectedValue = useSelector(state => state.administrador.programacoes_filters.equipe)

    useEffect(() => {
        setTimeout(() => {
            dispatch(setProgramacoesFilter({ attr, value: [] }))
            dispatch(setProgramacoesFilter({ attr, value: selectedValue[0] ? selectedValue : [defaultValue] }))
        }, 75)
    }, []);

    const handleChange = value => {
        dispatch(setProgramacoesFilter({ attr, value: [value] }))
    }

    return <FormControl variant='filled' required sx={{ width: '100%', maxWidth: '300px' }}>
        <InputLabel id="label">{placeholder}</InputLabel>
        <Select id={id} labelId={id} label={placeholder}
            value={selectedValue} onChange={e => handleChange(e.target.value)}
        >
            {items.map(i => <MenuItem value={i}>{i}</MenuItem>)}
        </Select>
    </FormControl>
}

const DialogDelete = (props) => {
    const dispatch = useDispatch()
    const { open, setOpen, programacao_id } = props

    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)
    
    const handleClose = () => { setOpen(false) }

    const agree = () => {
        dispatch(thunk_delete_programacao({tenantId, tokenId, programacao_id}))
        handleClose()
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Deseja excluir a programação?"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="descript-alerta">
                Ao excluir, a programação não entrará mais no cronograma da equipe.
            </DialogContentText>
            <br />
            <DialogContentText id="descript-cadastro">
                Se algum certificado já estiver cadastrado dentro dessa programação, não será possível excluí-la. Contate o seu administrador para mais detalhes.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => agree()} autoFocus>
                Excluir programação
            </Button>
        </DialogActions>
    </Dialog>
}

export default ProgramacoesWrapper