import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Store
import { thunk_get_equipes, thunk_get_resumo_gestor, thunk_get_usuarios } from "../../store/slices/slice_gestor";

// MUI
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Project Components
import SeletorAnos from '../auxComponents/selects/years'
import ControlledSelect from '../auxComponents/selects/controlledSelect'
import SeletorQuarter from "../auxComponents/selects/quarters"
import { getCurQuarter } from "../auxComponents/commonFuncs";
import { Accordion, Summary, UserLists } from "./components";

// Other Components
import Skeleton from "react-loading-skeleton";

// Styling
import "./new_styles.css";

function GerencialComponent() {
  const dispatch = useDispatch();

  const programacoes = useSelector((state) => state.gestor.resumo) || [];
  const usuarios = useSelector((state) => state.gestor.usuarios) || [];
  const [ano, setAno] = useState(new Date().getFullYear());
  const [quarter, setQuarter] = useState(getCurQuarter());
  const [equipe, setEquipe] = useState('');
  const loading = useSelector(state => state.gestor.resumo_status)

  const tenantId = useSelector(state => state.tenant.tenantId)
  const tokenId = useSelector(state => state.user.tokenId)

  const equipes = [
    ...new Set(usuarios.map(e => e.nome))
  ];

  const programacoesFiltradas = programacoes
    .filter(p => p.quarter === quarter && (equipe ? p.equipe === equipe : true))
    .filter(p => p.nivel_usuario == 'Colaborador' || p.nivel_usuario == 'Gestor')

  useEffect(() => {
    if (loading == null) {
      dispatch(thunk_get_resumo_gestor({ tenantId, tokenId, ano }));
      dispatch(thunk_get_usuarios({ tenantId, tokenId }));
      dispatch(thunk_get_equipes({ tenantId, tokenId }))
    }
  }, [loading]);

  useEffect(() => {
    dispatch(thunk_get_resumo_gestor({ tenantId, tokenId, ano }));
  }, [ano]);

  useEffect(() => {
    setEquipe('')
    equipe == '' ? setEquipe(equipes[0] || '') : setEquipe(equipe)
  }, [programacoes])

  const EquipeSelect = () => {
    return <div className='filter-equipe'>
      <ControlledSelect value={equipe} setValue={setEquipe}
        options={equipes} placeholder='Equipe'
        size='small' fullWidth variant="filled" />
    </div>
  }

  return <>

    {loading == 'done'
      ? <div className="selector-row">
        <SeletorAnos ano={ano} setAno={setAno} />
        <SeletorQuarter quarter={quarter} setQuarter={setQuarter} />
        <EquipeSelect />
      </div >

      : <div className="selector-row">
        <Skeleton variant='rectangular' width={'60px'} height={50} />
        <Skeleton variant='rectangular' width={'160px'} height={50} />
        <Skeleton variant='rectangular' width={'300px'} height={50} />
      </div >
    }

    <br />
    {loading == 'done'
      ? <CardsEquipes data={programacoesFiltradas} />
      : <div>
        <Skeleton variant='rectangular' width={'100%'} height={125} style={{ marginBottom: '10px' }} />
        <Skeleton variant='rectangular' width={'100%'} height={125} style={{ marginBottom: '10px' }} />
        <Skeleton variant='rectangular' width={'100%'} height={125} style={{ marginBottom: '10px' }} />
      </div>
    }

  </>
}

const CardsEquipes = props => {
  const { data } = props;

  const certificacoes = [...new Set(data.map(p => p.programacao_nome))]

  return <>
    <CardEquipe data={data} total={true} certificacao='Total da equipe' />
    {React.Children.toArray(certificacoes.map(c => <CardEquipe data={data.filter(p => p.programacao_nome == c)} total={false} certificacao={c} />))}
  </>
}

const CardEquipe = props => {
  const { data, total, certificacao } = props

  const icone = data[0]?.certificacoes[0]?.icone

  return <Accordion expanded={total ? false : undefined}>

    <AccordionSummary expandIcon={total ? undefined : <ExpandMoreIcon />} >
      <Summary total={total} icone={icone} data={data} certificacao={certificacao} />
    </AccordionSummary>

    {!total && <AccordionDetails>
      <UserLists data={data} />
    </AccordionDetails>}

  </Accordion>
}

export default GerencialComponent;