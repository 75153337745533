import { Subject } from "@mui/icons-material"
import { Button, Switch } from "@mui/material"
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

const cols = (changeStatusAtivo, setColabPage, setShow) => [
    {
        field: 'usuario_nome'
        , headerName: 'Nome'
        , width: '225'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'nome'
        , headerName: 'Equipe'
        , width: '200'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'grupo'
        , headerName: 'Grupo'
        , width: '250'
        , editable: false
        , defaultVisible: false
    }
    , {
        field: 'permissao'
        , headerName: 'Nível'
        , width: '150'
        , editable: false
        , defaultVisible: false
    }
    , {
        field: 'ativo'
        , headerName: 'Ativo'
        , width: '125'
        , renderCell: params => {
            return <Switch checked={params.row.ativo}
                onClick={() => changeStatusAtivo(params.row.usuario)} />
        }
    }
    , {
        field: 'excluir'
        , headerName: 'Tirar da equipe'
        , width: '125'
        , renderCell: params => {
            return <Button variant='contained' size='small'
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                onClick={() => setShow(params.row)} startIcon={<PersonRemoveIcon />} >
            </Button>
        }
    }
    , {
        field: 'resumo'
        , headerName: 'Consultar'
        , width: 125
        , renderCell: params => {
            return <Button variant='contained' size='small'
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                onClick={() => setColabPage(params.row.usuario)} startIcon={<Subject />} >
            </Button>
        }
    }
]

export default cols