export const getTypographyByTenant = (tenantConfig) => {
    return {
        fontFamily: [
            'Nunito'
        ].join(',')
        , pageTitle: {
            fontSize: '1.2rem',
            fontWeight: 500,
            fontFamily: 'Roboto',
            color: 'rgb(120,120,120)'
        }
    }
}

export const getMuiTypographyByTenant = (tenantConfig) => {
    return {
        defaultProps: {
            variantMapping: {

            },
        }
    }
}

