import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//GA4
import { dataLayerPush } from '../../ga4/dataLayer';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { thunk_get_usuarios, thunk_get_resumo_gestor, thunk_post_newColab, thunk_active_user, thunk_remove_colab_from_team, thunk_get_equipes } from "../../store/slices/slice_gestor";
import { fetching_message } from '../../store/slices/slice_errors';

// Styling
import "./styles.css";

// Components
import columnsInit from './columns'
import DataTable from '../auxComponents/dataTable';
import ControlledSelect from '../auxComponents/selects/controlledSelect'
import Modal from '../auxComponents/Modal/modal';

// Default components
import { GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid';
import { Save, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, InputAdornment, Skeleton, TextField } from '@mui/material';

const GestaoEquipeWrapper = () => {
    const dispatch = useDispatch();
    const [ano, setAno] = useState(new Date().getFullYear());
    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    const loading = useSelector(state => state.gestor.status_geral)
    const loadingUsuarios = useSelector(state => state.gestor.status_usuarios)
    const usuariosData = useSelector(state => state.gestor.usuarios)
    const resumoData = useSelector(state => state.gestor.resumo)

    useEffect(() => {
        if (loading == null) {
            dispatch(thunk_get_usuarios({tenantId, tokenId}))
            dispatch(thunk_get_resumo_gestor({tenantId, tokenId, ano}))
            dispatch(thunk_get_equipes({tenantId, tokenId}))
        }
    }, [loading])

    useEffect(() => {
        if (loadingUsuarios == null) {
            dispatch(thunk_get_usuarios({tenantId, tokenId}))
        }
    }, [loadingUsuarios])

    return <GestaoEquipe usuariosData={usuariosData} resumoData={resumoData} loading={loading} />
}

const GestaoEquipe = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { usuariosData, loading } = props
    const resumoData = useSelector(state => state.gestor.resumo)
    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    //const equipes = [...new Set(usuariosData.map(u => u.nome))].sort()
    const equipes = useSelector(state => state.gestor.equipes)
    const equipesNomes = equipes ? Object.keys(equipes) : [];

    const [equipe, setEquipe] = useState('')
    const [show, setShow] = useState();

    const setColabPage = (usuario_id) => {
        navigate(`/equipe/${usuario_id}`)
    }

    useEffect(() => {
        setEquipe('')
        equipe == '' ? setEquipe(equipesNomes[0] || '') : setEquipe(equipe)
    }, [usuariosData])

    const [showAtivos, setShowAtivos] = useState(true)

    const filteredUsuariosData = usuariosData
        .filter(u => !showAtivos ? true : u.ativo)
        .filter(u => equipe == '' ? true : u.nome == equipe)

    const changeStatusAtivo = usuario_id => {
        dispatch(fetching_message())
        dispatch(thunk_active_user({tenantId, tokenId, usuario_id}))
    }

    // o equipe id deve vir de uma lista de equipes :/
    const removeColabFromTeam = () => {
        dispatch(fetching_message())
        dispatch(thunk_remove_colab_from_team({ tenantId, tokenId, usuario: show.usuario, equipe: equipes[equipe] }))
        .unwrap().then((result) => {dataLayerPush({event: "ger_delete_user"})})
        setShow(false)
    }

    const rows = filteredUsuariosData.map((r, i) => ({ ...r, id: i }))
    const columns = columnsInit(changeStatusAtivo, setColabPage, setShow)

    const CustomToolbar = () => {
        return <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <Button variant="text" size="small" disabled={false}
                startIcon={showAtivos ? <Visibility /> : <VisibilityOff />}
                onClick={() => setShowAtivos(!showAtivos)}>
                {showAtivos ? 'Mostrar tudo' : 'Mostrar apenas ativos'}
            </Button>
        </GridToolbarContainer>
    }

    const showModal = () => {
        setShow(false)
    }

    return <>
        <div className='gestao-equipes'>
            <div className='gestao-equipe-filters'>
                <div className='filter-equipe'>
                    {loading == 'done'
                        ? <ControlledSelect value={equipe} setValue={setEquipe}
                            options={equipesNomes} placeholder='Equipe'
                            size='small' fullWidth variant="filled" />
                        : <Skeleton variant='rectangular' width={'100%'} height={55} />
                    }
                </div>
                {loading == 'done'
                    ? <AddColab equipe={equipe} equipes={equipes} />
                    : <Skeleton variant='rectangular' width={'100%'} height={55} />
                }
            </div>
            {loading == 'done'
                ? <DataTable rows={rows} columns={columns} customToolbar={CustomToolbar}
                    defaultSort={[{ field: 'usuario_nome', sort: 'asc' }]} />
                : <Skeleton variant='rectangular' width={'100%'} height={400} />
            }
        </div>
        <Modal onClose={() => setShow(false)} show={show}>
            <div className='remove-modal'>
                <p className='message-modal'>Atenção: Esta ação removerá o usuário {show?.usuario_nome} de sua equipe</p>
                <div className='btn-remove-area'>
                    <button className='btn-modal-action-invert' onClick={showModal}>Cancelar</button>
                    <button className='btn-modal-action' onClick={() => removeColabFromTeam(show.usuario)}>Remover</button>
                </div>
            </div>
        </Modal>
    </>
}

const AddColab = (props) => {
    const dispatch = useDispatch()
    const { equipe, equipes } = props
    const [addColab, setAddColab] = useState('')
    const resumoData = useSelector(state => state.gestor.resumo)
    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)
    const primaryAuthDomain = useSelector(state => state.tenant.primaryAuthDomain);

    const saveAddColab = () => {
        const user = addColab.replace(`@${primaryAuthDomain}`, '') + `@${primaryAuthDomain}`
        dispatch(thunk_post_newColab({ equipe: equipes[equipe], user, tenantId, tokenId }))
        .unwrap().then((result) => {dataLayerPush({event: "ger_add_user"})})
        setTimeout(() => setAddColab(''), 2000)
    }

    return < div className='add-colab-section' >
        <TextField label="Adicione uma pessoa à equipe" value={addColab}
            onChange={(e) => setAddColab(e.target.value)}
            size='small' margin='none' variant="filled" fullWidth sx={{ padding: '0' }}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">@{primaryAuthDomain}</InputAdornment>
            }}
        />

        <Button variant="contained" size="big" startIcon={<Save />}
            onClick={() => saveAddColab()}>
            Salvar
        </Button>
    </div >
}

export default GestaoEquipeWrapper