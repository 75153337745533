import React, { useEffect, useState } from 'react';

//GA4
import { dataLayerPush } from '../../ga4/dataLayer';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { thunk_delete_certificacao, thunk_post_certificacao, thunk_put_certificacao } from '../../store/slices/slice_administrador';

// Styling
import "./styles.css";

// Components
import Modal from '../auxComponents/Modal/modal';
import ControlledSelect from '../auxComponents/selects/controlledSelect'

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText
    , DialogTitle, InputAdornment, TextField, Typography
} from '@mui/material';

import { Save } from '@mui/icons-material';

const icons = {
    'Google': 'https://storage.cloud.google.com/certificacoes-icherry-logos/google_mobile.svg'
    , 'Microsoft': 'https://storage.cloud.google.com/certificacoes-icherry-logos/microsoft_bing.svg'
    , 'Meta': 'https://storage.cloud.google.com/certificacoes-icherry-logos/meta.svg'
    , 'Twitter': 'https://storage.cloud.google.com/certificacoes-icherry-logos/twitter.svg'
    , 'Criteo': 'https://storage.cloud.google.com/certificacoes-icherry-logos/criteo.svg'
    , 'Mercado Livre': 'https://storage.cloud.google.com/certificacoes-icherry-logos/mercado_ads.svg'
    , 'Amazon': 'https://storage.cloud.google.com/certificacoes-icherry-logos/amazon.svg'
    , 'SEM Rush': 'https://storage.cloud.google.com/certificacoes-icherry-logos/semrush.svg'
    , "Globo": 'https://storage.cloud.google.com/certificacoes-icherry-logos/globo.svg'
}

export const ModalCadastro = (props) => {
    const dispatch = useDispatch()
    const { modalCadastroOpen, setModalCadastroOpen, grupos, empresas, data, mode = 'put' } = props

    useEffect(() => {
        setNome(data?.nome || '')
        setEmpresa(data?.empresa || '')
        setUrlConteudo(data?.url_conteudo || '')
        setUrlProva(data?.url_prova || '')
        setDescricao(data?.descricao || '')
        setMeses(data?.validade?.quantidade || 12)
        setGrupoRelatorio(data?.grupo_relatorio || '')
    }, [modalCadastroOpen])

    const [nome, setNome] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [url_conteudo, setUrlConteudo] = useState('')
    const [url_prova, setUrlProva] = useState('')
    const [descricao, setDescricao] = useState('')
    const [meses, setMeses] = useState(12)
    const [grupo_relatorio, setGrupoRelatorio] = useState('')

    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    const save = () => {
        const saveData = {
            nome, empresa, grupo_relatorio, url_conteudo, url_prova, descricao
            , validade: {
                dimensao: "meses",
                quantidade: +meses
            }, icone: data?.icone ? data.icone : icons[empresa]
        }
        if (mode == 'put') {
            dispatch(thunk_put_certificacao({tenantId, tokenId, data: saveData}))
            .unwrap().then((result) => {dataLayerPush({event: "admin_add_cert"})})
        } else {
            console.log(data)
            dispatch(thunk_post_certificacao({ data: saveData, id: data.certificacao_id, tenantId, tokenId }))
            .unwrap().then((result) => {dataLayerPush({event: "admin_edit_cert"})})
        }

        setModalCadastroOpen(false)
    }

    return <Modal title="Modal Salvar Certificados" onClose={() => setModalCadastroOpen(false)} show={modalCadastroOpen}>
        <div className='modal-body-contents'>
            <Typography>{mode == 'put' ? 'Insira os dados da nova certificação:' : 'Edite ou consulte os dados da certificação'}:</Typography>

            <div className='modal-cadastro-filters'>
                <TextField className='text-modal-cert' label="Nome da certificação" value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    size='small' margin='none' variant="filled" fullWidth />

                <ControlledSelect options={empresas} placeholder='Empresa'
                    value={empresa} setValue={setEmpresa}
                    size='small' fullWidth variant="filled" />

                <ControlledSelect options={grupos} placeholder='Grupo de relatório'
                    value={grupo_relatorio} setValue={setGrupoRelatorio}
                    size='small' fullWidth variant="filled" />

                <TextField className='text-modal-cert' label="Link do conteúdo" value={url_conteudo}
                    onChange={(e) => setUrlConteudo(e.target.value)}
                    size='small' margin='none' variant="filled" fullWidth />

                <TextField className='text-modal-cert' label="Link da prova" value={url_prova}
                    onChange={(e) => setUrlProva(e.target.value)}
                    size='small' margin='none' variant="filled" fullWidth />

                <TextField className='text-modal-cert' label="Validade" value={meses}
                    onChange={(e) => setMeses(e.target.value)} type={'number'}
                    InputProps={{ endAdornment: <InputAdornment position="end">meses</InputAdornment> }}
                    size='small' margin='none' variant="filled" fullWidth />

                <TextField className='text-modal-cert' label="Descrição" value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    multiline minRows={4} size='small' variant="filled" margin='none' fullWidth />
            </div>

            <Button variant="contained" size="big" startIcon={<Save />}
                onClick={() => save()}>
                {mode == 'put' ? 'Cadastrar certificação' : 'Salvar alterações'}
            </Button>
        </div>
    </Modal>
}

export const DialogDelete = (props) => {
    const dispatch = useDispatch()
    const { open, setOpen, certificacaoId } = props

    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    const handleClose = () => { setOpen(false) }

    const agree = () => {
        dispatch(thunk_delete_certificacao({tenantId, tokenId, certificacaoId}))
        .unwrap().then((result) => {dataLayerPush({event: "admin_delete_cert"})})
        handleClose()
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Deseja excluir a certificação?</DialogTitle>
        <DialogContent>
            <DialogContentText id="descript-alerta">
                Ao excluir, a certificação não estará mais disponível para uso em programações.
            </DialogContentText>
            <br />
            <DialogContentText id="descript-cadastro">
                Se algum certificado já estiver cadastrado dentro dessa certificação ou se a certificação já estiver programada, não será possível excluí-la. Contate o seu administrador para mais detalhes.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => agree()} autoFocus>
                Excluir certificação
            </Button>
        </DialogActions>
    </Dialog>
}