import React, { useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setLogoff } from '../../store/slices/slice_user';

// Router
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Styling and assets
import './styles.css'
import ic_logo from '../../assets/ic.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import _menu from './menuList.json'


const MenuComponent = () => {
    const dispatch = useDispatch();

    const btn_logoff = () => {
        dispatch(setLogoff())
    }

    const usuario = useSelector(state => state.user)
    const tenantConfig = useSelector(state => state.tenant)

    const avatar = usuario?.photoURL ? <img className='avatar-picture' src={usuario.photoURL} alt='foto do usuario' /> : <AccountCircleIcon sx={{width: '1.75em', height: '1.75em'}}/>

    const [barExpanded, setbarExpanded] = useState(false)
    const toggleBar = () => {
        setbarExpanded(!barExpanded)
    }

    return <div className={'sidebar' + (!barExpanded ? ' sidebarColapse' : '')}>
        <Link to={'/dashboard'}>   <img className='ic' src={tenantConfig?.style.logo.menu} style={{ fill: 'var(--contrast-text-color)' }} /></Link>
        <div className='user'>
            <div className='lines'>
                <div style={{ display: 'flex', 'justifyContent': 'center', alignItems: 'center' }}>
                    <div className='userAvatar' style={{ display: 'flex', flexDirection: 'column', 'justifyContent': 'center', alignItems: 'center' }}>
                        {avatar}
                        <p className='user-name'>{usuario.displayName}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='menuButton' onClick={toggleBar}>
            {barExpanded ? <KeyboardDoubleArrowLeftIcon fontSize='large' /> : <KeyboardDoubleArrowRightIcon fontSize='large' />}
        </div>

        <div className='MenuList'>
            <ul className='ul'>
                {usuario.claims.nivel !== "RH" &&
                    <>
                        <li className='userType'>Colaborador</li>
                        <Link to={'/dashboard'}><li className='menulink'>Minhas certificações</li></Link>
                        <Link to={'/cronograma'}> <li className='menulink last'>Cronograma</li></Link>
                    </>
                }
                {usuario.claims?.Gestor || usuario.claims?.Diretor || usuario.claims?.Admin ?
                    <>
                        <li className='userType'>Gerencial</li>
                        <Link to={'/relatorio'}> <li className='menulink '>Relatório</li></Link>
                        <Link to={'/gerencial'}><li className='menulink'>Resumo</li></Link>
                        <Link to={'/equipe'}><li className='menulink last'>Equipes</li></Link>
                    </> : <></>}
                {usuario.claims?.RH &&
                    <>
                        <li className='userType'>Gerencial</li>
                        <Link to={'/relatorio'}> <li className='menulink '>Relatório</li></Link>
                        <li className='userType'>Administrador</li>
                        <Link to={'/cronograma'}> <li className='menulink last'>Cronograma</li></Link>
                    </>}
                {usuario.claims?.Admin ?
                    <>
                        <li className='userType'>Administrador</li>
                        <Link to={'/certificacoes'}> <li className='menulink '>Certificações</li></Link>
                        <Link to={'/programacoes'}> <li className='menulink last'>Programações</li></Link>
                    </>
                    : <></>}
                <></>
                <li className='menulink exit' onClick={() => btn_logoff()}>
                    <>
                        <LogoutIcon className='logout-icon' /> <p className='sair-p'>Sair</p>
                    </>
                </li>
            </ul>
        </div>
    </div>

}

export default MenuComponent;