import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";
import firebaseConfig from '../secrets/firebaseCredentials.json'

const app = initializeApp(firebaseConfig);
const defaultApp = initializeApp(firebaseConfig);

export const auth = getAuth(app);
/* connectAuthEmulator(auth, "http://127.0.0.1:9099"); */
export const perf = getPerformance(app);
export const provider = new GoogleAuthProvider();

export const defaultDb = getFirestore(defaultApp, '(default)');

export const getDbByTenantId = (tenantId) => {
  return getFirestore(app, tenantId)
}

export const getAuthByTenantId = (tenantId) => {
  auth.tenantId = tenantId
  return auth
}