import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// Redux
import { Provider } from 'react-redux'
import store from './store/store'

// MUI Provider
import { ThemeProvider } from '@mui/material';
import superTheme from './theming/superTheme/superTheme'

// MUI Date Picker and locale
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/pt-br';

// MUI Global fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Assets and components
import App from './App';
import './index.css';
import JiraModal from './components/auxComponents/Jira/jiraModal';
import ErrorsSnackbar from './components/errorsSnackbar';
import TenantProvider from './providers/TenantProvider/TenantProvider'
import AuthProvider from './providers/AuthProvider/AuthProvider';

import { SkeletonTheme } from 'react-loading-skeleton';


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={superTheme}>
        <SkeletonTheme>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <TenantProvider>
              <AuthProvider>

                <ErrorsSnackbar />
                <App />

              </AuthProvider>
            </TenantProvider>
            <JiraModal />
          </LocalizationProvider>
        </SkeletonTheme>
      </ThemeProvider>
    </Provider >
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
