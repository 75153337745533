import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import getApi from '../api'

const slice = createSlice({
    name: 'colaborador',
    initialState: { resumo: [], initialized: 'pending', certificado_post: 'done' },
    reducers: {
    },
    extraReducers(builder) {
        builder
            //Ações de API
            .addCase(thunk_get_resumo.rejected, (state, action) => {
                return Object.assign(state, { resumo: [] }, { initialized: 'rejected' })
            })
            .addCase(thunk_get_resumo.pending, (state, action) => {
                return Object.assign(state, { resumo: [] }, { initialized: 'pending' })
            })
            .addCase(thunk_get_resumo.fulfilled, (state, action) => {
                return Object.assign(state, { resumo: action.payload }, { initialized: 'done' })
            })
            .addCase(thunk_post_certificado.rejected, (state, action) => {
                return Object.assign(state, { certificado_post: 'done' })
            })
            .addCase(thunk_post_certificado.pending, (state) => {
                return Object.assign(state, { certificado_post: 'pending' })
            })
            .addCase(thunk_post_certificado.fulfilled, (state) => {
                return Object.assign(state, { certificado_post: 'done' })
            })
            .addCase(thunk_put_certificado.rejected, (state) => {
                return Object.assign(state, { certificado_post: 'done' })
            })
            .addCase(thunk_put_certificado.pending, (state) => {
                return Object.assign(state, { certificado_post: 'pending' })
            })
            .addCase(thunk_put_certificado.fulfilled, (state) => {
                return Object.assign(state, { certificado_post: 'done' })
            })
            .addCase(thunk_delete_certificado.rejected, (state) => {
                return Object.assign(state, { certificado_post: 'done' })
            })
            .addCase(thunk_delete_certificado.pending, (state) => {
                return Object.assign(state, { certificado_post: 'pending' })
            })
            .addCase(thunk_delete_certificado.fulfilled, (state) => {
                return Object.assign(state, { certificado_post: 'done' })
            })
    }
})

// API fetch
const apiController = {
    thunk_get_resumo: (payload) => ({ endpoint: `/api/colaborador/resumo?ano=${payload.ano}`, method: 'GET', payload })
    , thunk_put_certificado: (payload) => ({ endpoint: `/api/colaborador/certificado`, method: 'PUT', body: payload.data, payload })
    , thunk_post_certificado: (payload) => ({ endpoint: `/api/colaborador/certificado`, method: 'POST', body: payload.data, payload })
    , thunk_delete_certificado: (payload) => ({ endpoint: `/api/colaborador/certificado?id=${payload.certificadoId}`, method: 'DELETE', payload })

}

export const thunk_get_resumo = createAsyncThunk('colaborador/thunk_get_resumo', async (payload) => await getApi(apiController.thunk_get_resumo(payload)))
export const thunk_post_certificado = createAsyncThunk('colaborador/thunk_post_certificado', async (payload) => await getApi(apiController.thunk_post_certificado(payload)))
export const thunk_delete_certificado = createAsyncThunk('colaborador/thunk_delete_certificado', async (payload) => await getApi(apiController.thunk_delete_certificado(payload)))
export const thunk_put_certificado = createAsyncThunk('colaborador/thunk_put_certificado', async (payload) => await getApi(apiController.thunk_put_certificado(payload)))

// Selectors
export const selector_get_resumo = (state) => {
    return state.resumo
}

export const { reset_certificado_post } = slice.actions

export default slice.reducer