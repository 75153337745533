import { Snackbar } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const ErrorsSnackbar = () => {
    const data = useSelector(state => state.errors.errors)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState(null)

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (data.length > 0) {
            setOpen(true)
            setMessage(data.slice(-1)[0].text)
        }
    }, [data])

    return <Snackbar
        open={open}
        autoHideDuration={message == 'Salvando alterações...' ? 5000 : 10000}
        onClose={handleClose}
        message={message}
    />
}

export default ErrorsSnackbar