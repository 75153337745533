import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect } from 'react';
import { getCurQuarter } from '../commonFuncs';

const Toggle = (props) => {
    const { quarter, setQuarter } = props

    const quarters = [1, 2, 3, 4]
    const curQuarter = getCurQuarter()

    return <ToggleButtonGroup value={quarter} onChange={(e, v) => setQuarter(v || curQuarter)}
        aria-label="text alignment" exclusive >
        {quarters.map(a => <ToggleButton value={a} key={a} aria-label={`Ano ${a}`}>Q{a}</ToggleButton>)}
    </ToggleButtonGroup>
}

export default Toggle