import React, { useEffect, useState } from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { setRelatorioFilter } from "../../store/slices/slice_administrador"

// MUI
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, Skeleton } from "@mui/material"
import { Clear } from "@mui/icons-material"

const SelectRelatorio = (props) => {
    const dispatch = useDispatch()
    const { data, placeholder, attr, loading } = props

    const defaultValue = useSelector(state => state.administrador.relatorio_filters[attr]) || []

    const relatorioFetchStatus = useSelector(state => state.administrador.relatorio_status)

    const [value, setValue] = useState([])

    useEffect(() => {
        if (relatorioFetchStatus == 'done') {
            setValue(defaultValue ? defaultValue : [])
        }
    }, [relatorioFetchStatus])

    const optionsArray = data.map(d => d[attr])

    const optionsFlattened = [...new Set([].concat.apply([], optionsArray))].sort().filter(o => o != '' || o != null)

    const setData = e => {
        dispatch(setRelatorioFilter({ attr, value: e || [] }))
        setValue(e)
    }

    const clear = () => {
        dispatch(setRelatorioFilter({ attr, value: [] }))
        setValue([])
    }

    const clearIcon = () => < Button sx={{ display: value.length == 0 ? "none" : "", width: '10px !important', minWidth: '52px !important', height: '100%' }}
        endIcon={<Clear />} onClick={clear} />

    return loading == 'done'
        ? <FormControl size='small' >
            <InputLabel id="label">{placeholder}</InputLabel>
            <Select multiple id={`select-${placeholder}`} labelId={`select-${placeholder}`} label={placeholder}

                inputProps={value.length !== 0 ? { IconComponent: () => clearIcon() } : undefined}
                value={value} onChange={e => setData(e.target.value)}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
            >
                {React.Children.toArray(optionsFlattened.map(i => <MenuItem value={i}>{i}</MenuItem>))}
            </Select>
        </FormControl >
        : <Skeleton variant='rectangular' width={'100%'} height={45} />
}

export const SelectEquipes = (props) => {
    const { data, placeholder, attr, loading, value, setValue } = props

    const optionsArray = data.map(d => d[attr])

    const optionsFlattened = [...new Set([].concat.apply([], optionsArray))].sort().filter(o => o != '' || o != null)

    const setData = e => {
        setValue(e)
    }

    const clear = () => {
        setValue([])
    }

    const clearIcon = () => < Button sx={{ display: value.length == 0 ? "none" : "", width: '10px !important', minWidth: '52px !important', height: '100%' }}
        endIcon={<Clear />} onClick={clear} />

    return loading == 'done'
        ? <FormControl size='small' >
            <InputLabel id="label">{placeholder}</InputLabel>
            <Select multiple id={`select-${placeholder}`} labelId={`select-${placeholder}`} label={placeholder}

                inputProps={value.length !== 0 ? { IconComponent: () => clearIcon() } : undefined}
                value={value} onChange={e => setData(e.target.value)}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
            >
                {React.Children.toArray(optionsFlattened.map(i => <MenuItem value={i}>{i}</MenuItem>))}
            </Select>
        </FormControl >
        : <Skeleton variant='rectangular' width={'100%'} height={45} />
}

export default SelectRelatorio