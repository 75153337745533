import { useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Components
import Select from '@mui/material/Select';
import { Add, Delete, Save } from '@mui/icons-material';
import { Button, FormControl, InputLabel, MenuItem, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { thunk_post_programacao } from '../../store/slices/slice_administrador';

import certTypes from '../certTypes'

const CadastroProgramacoes = (props) => {
    const dispatch = useDispatch()
    const filters = useSelector(state => state.administrador.programacoes_filters)
    const certificacoes = useSelector(state => state.administrador.certificacoes)
    const equipes = useSelector(state => state.administrador.equipes)

    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    const equipe_id = equipes.find(e => e.nome == filters.equipe)?.equipe_id
    const [opcao, setOpcao] = useState('Obrigatória')

    const [certificacao, setCertificacao] = useState({})
    const [qtdCerts, setQtdCerts] = useState(1)

    const options = certificacoes
        .map(c => c.certificacao_id)
        .sort()

    const save = () => {
        const certificacoes_formatted = Object.keys(certificacao).map(c => ({ nome: certificacao[c], certificacao_id: certificacoes.find(r => r.nome == certificacao[c]).certificacao_id }))
        const quarter = filters.quarter[0]
        const equipe = filters.equipe[0]
        const ano = filters.ano[0]

        const postData = { equipe_id, quarter, equipe, ano, tipo: opcao, certificacoes: certificacoes_formatted }
        dispatch(thunk_post_programacao({tenantId, tokenId, postData}))
        props.closeModal()
    }

    const changeOpcao = opcao => {
        setOpcao(opcao)
        opcao == 'Alternativas' ? setQtdCerts(3) : setQtdCerts(1)
    }

    return <div className='cadastro-programacao'>
        <div className='cadastro-programacao-header'>
            <div className='cadastro-programacao-info'>
                <div>Ano: {filters.ano}</div>
                <div>Quarter: {filters.quarter}</div>
                <div>Equipe: {filters.equipe}</div>
            </div>
            <hr style={{ width: '95%' }} />

            <ToggleOpcoes opcao={opcao} setOpcao={changeOpcao} />
        </div>

        {opcao == 'Alternativas' && <div className='cadastro-programacao-opcionais-title cadastro-programacao-alternativas-title'>
            Selecione as certificações alternativas a serem programadas:
        </div>}

        <CertificacoesWrapper qtdCerts={qtdCerts} setQtdCerts={setQtdCerts} certificacoes={certificacao} setCertificacoes={setCertificacao} options={options} />
        {opcao == 'Alternativas' && <Button variant='text' startIcon={<Add />} onClick={() => setQtdCerts(qtdCerts + 1)}>Adicionar opção</Button>}
        <Button variant='contained' startIcon={<Save />} onClick={() => save()}>Salvar</Button>
    </div>
}

const CertificacoesWrapper = (props) => {
    const { qtdCerts, setQtdCerts, certificacoes, setCertificacoes } = props

    const changeValues = (id, value) => {
        setCertificacoes({ ...certificacoes, [id]: value })
    }

    const removeCert = (id) => {
        if (qtdCerts > 2) {
            setQtdCerts(qtdCerts - 1)
            var newCertificacoes = {}

            Object.keys(certificacoes).forEach(k => {
                if (k < id) {
                    newCertificacoes[k] = certificacoes[k]
                } else if (k > id) {
                    newCertificacoes[k - 1] = certificacoes[k]
                }
            })

            setCertificacoes(newCertificacoes)
        }
    }

    return [...Array(qtdCerts).keys()].map(id => {
        return <div className='cert-opcao'>
            <SelectCertificacao
                id={id}
                placeholder='Certificação'
                value={certificacoes?.[id] || null} setValue={value => changeValues(id, value)}
                styles={{ width: '100%' }}
            />
            {qtdCerts > 2 && <div className='delete-button'><Button variant='text' startIcon={<Delete />} onClick={() => removeCert(id)} /></div>}
            <br />
        </div>
    })
}

const ToggleOpcoes = (props) => {
    const { opcao, setOpcao } = props

    return <ToggleButtonGroup value={opcao} onChange={(e, v) => setOpcao(v || 'Única')}
        aria-label="text alignment" exclusive >
        {['Obrigatória', 'Opcional', 'Alternativas'].map(a =>
            <ToggleButton value={a} aria-label={`Opção ${a}`}>
                <Tooltip title={certTypes[a].tooltip} >
                    <span>{a}</span>
                </Tooltip>
            </ToggleButton>
        )}
    </ToggleButtonGroup>
}

const SelectCertificacao = (props) => {
    const { value, setValue, id, placeholder } = props
    const certificacoes = useSelector(state => state.administrador.certificacoes)
    const items = certificacoes.map(c => c.nome).sort()

    // TODO validar se duas certificacoes iguais nao estao na programacao
    return <FormControl variant='filled' sx={{ width: '80%' }}>
        <InputLabel id="label">{placeholder}</InputLabel>
        <Select id={`select-${id}`} labelId={`select-${id}`} label='Certificação'
            value={value} onChange={e => setValue(e.target.value)}
        >
            {items.map(i => <MenuItem value={i}>{i}</MenuItem>)}
        </Select>
    </FormControl>
}

export default CadastroProgramacoes