import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import getApi from '../api'

const curQuarter = () => Math.floor(new Date().getMonth() / 3 + 1)
const curAno = () => new Date().getFullYear()

const slice = createSlice({
    name: 'administrador',
    initialState: {
        cronograma: [], cronograma_status: 'pending'
        , relatorio: [], relatorio_status: 'pending', relatorio_filters: { quarter: [curQuarter()], tipo: ['Obrigatória', 'Alternativas'] }
        , programacoes: [], programacoes_status: 'pending', programacoes_filters: { quarter: [curQuarter()], ano: [curAno()], equipe: [] }
        , equipes: [], equipes_status: 'pending'
        , certificacoes: [], certificacoes_status: null
        , programacoes_status_geral: 'pending'
        , equipes_gestor: [], equipes_gestor_status: 'pending'
    },
    reducers: {
        resetRelatorioFilter(state) {
            const new_filters = { relatorio_filters: { quarter: [curQuarter()], tipo: ['Obrigatória', 'Alternativas'] } }
            return Object.assign(state, new_filters)
        }
        , setRelatorioFilter(state, action) {
            const { attr, value } = action.payload
            const new_filters = { relatorio_filters: { ...state.relatorio_filters, [attr]: value } }
            return Object.assign(state, new_filters)
        }
        , setProgramacoesFilter(state, action) {
            const { attr, value } = action.payload
            const new_filters = { programacoes_filters: { ...state.programacoes_filters, [attr]: value } }
            return Object.assign(state, new_filters)
        }
    },
    extraReducers(builder) {
        //Ações de API
        builder
            .addCase(thunk_get_cronograma.rejected, (state) => {
                return Object.assign(state, { cronograma_status: 'rejected' })
            })
            .addCase(thunk_get_cronograma.pending, (state) => {
                return Object.assign(state, { cronograma_status: 'pending' })
            })
            .addCase(thunk_get_cronograma.fulfilled, (state, action) => {
                return Object.assign(state, { cronograma: action.payload }, { cronograma_status: 'done' })
            })

            .addCase(thunk_get_relatorio.rejected, (state) => {
                return Object.assign(state, { relatorio_status: 'rejected' })
            })
            .addCase(thunk_get_relatorio.pending, (state) => {
                return Object.assign(state, { relatorio_status: 'pending' })
            })
            .addCase(thunk_get_relatorio.fulfilled, (state, action) => {
                return Object.assign(state, { relatorio: action.payload }, { relatorio_status: 'done' })
            })

            //// Aba PROGRAMAÇÕES
            .addCase(thunk_get_programacoes.rejected, (state) => {
                return Object.assign(state, { programacoes_status: 'rejected' })
            })
            .addCase(thunk_get_programacoes.pending, (state) => {
                return Object.assign(state, { programacoes_status: 'pending' }, { programacoes_status_geral: 'pending' })
            })
            .addCase(thunk_get_programacoes.fulfilled, (state, action) => {
                const status_geral = state.equipes_status == 'done' && state.certificacoes_status == 'done' ? { programacoes_status_geral: 'done' } : {}
                return Object.assign(state, { programacoes: action.payload }, { programacoes_status: 'done' }, status_geral)
            })

            .addCase(thunk_get_equipes.rejected, (state) => {
                return Object.assign(state, { equipes_status: 'rejected' })
            })
            .addCase(thunk_get_equipes.pending, (state) => {
                return Object.assign(state, { equipes_status: 'pending' }, { programacoes_status_geral: 'pending' })
            })
            .addCase(thunk_get_equipes.fulfilled, (state, action) => {
                const status_geral = state.programacoes_status == 'done' && state.certificacoes_status == 'done' ? { programacoes_status_geral: 'done' } : {}
                return Object.assign(state, { equipes: action.payload }, { equipes_status: 'done' }, status_geral)
            })

            .addCase(thunk_get_equipes_gestor.rejected, (state) => {
                return Object.assign(state, { equipes_gestor_status: 'rejected' })
            })
            .addCase(thunk_get_equipes_gestor.pending, (state) => {
                return Object.assign(state, { equipes_gestor_status: 'pending' })
            })
            .addCase(thunk_get_equipes_gestor.fulfilled, (state, action) => {
                return Object.assign(state, { equipes_gestor: action.payload }, { equipes_gestor_status: 'done' })
            })

            .addCase(thunk_get_certificacoes.rejected, (state) => {
                return Object.assign(state, { equipes_status: 'rejected' })
            })
            .addCase(thunk_get_certificacoes.pending, (state) => {
                return Object.assign(state, { equipes_status: 'pending' }, { programacoes_status_geral: 'pending' })
            })
            .addCase(thunk_get_certificacoes.fulfilled, (state, action) => {
                const status_geral = state.programacoes_status == 'done' && state.equipes_status ? { programacoes_status_geral: 'done' } : {}
                return Object.assign(state, { certificacoes: action.payload }, { certificacoes_status: 'done' }, status_geral)
            })

            // DELETE de programações
            .addCase(thunk_delete_programacao.rejected, (state) => {
                return Object.assign(state, { programacoes_status_geral: 'deleted' })
            })
            .addCase(thunk_delete_programacao.pending, (state) => {
                return Object.assign(state, { programacoes_status_geral: 'deleting' })
            })
            .addCase(thunk_delete_programacao.fulfilled, (state, action) => {
                return Object.assign(state, { programacoes_status_geral: 'deleted' })
            })

            // Aba CERTIFICAÇÔES
            .addCase(thunk_put_certificacao.rejected, (state) => {
                return Object.assign(state, { certificacao_post: 'rejected' })
            })
            .addCase(thunk_put_certificacao.pending, (state) => {
                return Object.assign(state, { certificacao_post: 'pending' })
            })
            .addCase(thunk_put_certificacao.fulfilled, (state) => {
                return Object.assign(state, { certificacao_post: 'done' }, { certificacoes_status: null })
            })

            .addCase(thunk_post_certificacao.rejected, (state) => {
                return Object.assign(state, { certificacao_post: 'rejected' })
            })
            .addCase(thunk_post_certificacao.pending, (state) => {
                return Object.assign(state, { certificacao_post: 'pending' })
            })
            .addCase(thunk_post_certificacao.fulfilled, (state) => {
                return Object.assign(state, { certificacao_post: 'done' }, { certificacoes_status: null })
            })

            .addCase(thunk_delete_certificacao.rejected, (state) => {
                return Object.assign(state, { certificacao_post: 'rejected' })
            })
            .addCase(thunk_delete_certificacao.fulfilled, (state) => {
                return Object.assign(state, { certificacoes_status: null })
            })

    }
})

//Fetch
const apiController = {
    thunk_get_cronograma: (payload) => ({ endpoint: `/api/admin/cronograma?ano=${payload.ano}`, method: 'GET', payload })
    , thunk_get_relatorio: (payload) => ({ endpoint: `/api/relatorio?ano=${payload.ano}`, method: 'GET', payload })
    , thunk_get_programacoes: (payload) => ({ endpoint: `/api/admin/programacao`, method: 'GET', payload })
    , thunk_get_equipes: (payload) => ({ endpoint: `/api/admin/equipes`, method: 'GET', payload })
    , thunk_get_equipes_gestor: (payload) => ({ endpoint: `/api/equipes/usuarios`, method: 'GET', payload })
    , thunk_get_certificacoes: (payload) => ({ endpoint: `/api/admin/certificacao`, method: 'GET', payload })
    , thunk_get_grupo_relatorios: (payload) => ({ endpoint: `/api/admin/certificacao/grupo`, method: 'GET', payload })

    , thunk_put_certificacao: (payload) => ({ endpoint: `/api/admin/certificacao`, method: 'PUT', body: payload.data, payload })
    , thunk_post_certificacao: (payload) => ({ endpoint: `/api/admin/certificacao?id=${payload.id}`, method: 'POST', body: payload.data, payload })
    , thunk_delete_certificacao: (payload) => ({ endpoint: `/api/admin/certificacao?id=${payload.certificacaoId}`, method: 'DELETE', payload })

    , thunk_post_programacao: (payload) => ({ endpoint: `/api/admin/programacao`, method: 'PUT', body: payload.postData, payload })
    , thunk_delete_programacao: (payload) => ({ endpoint: `/api/admin/programacao?id=${payload.programacao_id}`, method: 'DELETE', payload })
}

export const thunk_get_cronograma = createAsyncThunk('administrador/thunk_get_cronograma', async (payload) => await getApi(apiController.thunk_get_cronograma(payload)))

export const thunk_get_relatorio = createAsyncThunk('administrador/thunk_get_relatorio', async (payload) => await getApi(apiController.thunk_get_relatorio(payload)))
export const thunk_get_programacoes = createAsyncThunk('administrador/thunk_get_programacoes', async (payload) => await getApi(apiController.thunk_get_programacoes(payload)))
export const thunk_get_equipes = createAsyncThunk('administrador/thunk_get_equipes', async (payload) => await getApi(apiController.thunk_get_equipes(payload)))
export const thunk_get_equipes_gestor = createAsyncThunk('administrador/thunk_get_equipes_gestor', async (payload) => await getApi(apiController.thunk_get_equipes_gestor(payload)))
export const thunk_get_certificacoes = createAsyncThunk('administrador/thunk_get_certificacoes', async (payload) => await getApi(apiController.thunk_get_certificacoes(payload)))
export const thunk_get_grupo_relatorios = createAsyncThunk('administrador/thunk_grupo_relatorios', async (payload) => await getApi(apiController.thunk_get_grupo_relatorios(payload)))

export const thunk_put_certificacao = createAsyncThunk('administrador/thunk_put_certificacao', async (payload) => await getApi(apiController.thunk_put_certificacao(payload)))
export const thunk_post_certificacao = createAsyncThunk('administrador/thunk_post_certificacao', async (payload) => await getApi(apiController.thunk_post_certificacao(payload)))
export const thunk_delete_certificacao = createAsyncThunk('administrador/thunk_delete_certificacao', async (payload) => await getApi(apiController.thunk_delete_certificacao(payload)))

export const thunk_post_programacao = createAsyncThunk('administrador/thunk_post_programacao', async (payload) => await getApi(apiController.thunk_post_programacao(payload)))
export const thunk_delete_programacao = createAsyncThunk('administrador/thunk_delete_programacao', async (payload) => await getApi(apiController.thunk_delete_programacao(payload)))

//Selectors
export const get_filtered_relatorio = (state) => {
    const filters = state.administrador.relatorio_filters
    var data = state.administrador.relatorio

    // Cruza os dados com os valores nos filtros
    Object.keys(filters).forEach(filterAttribute => {
        const filter_value = filters[filterAttribute]
        if (filter_value && filter_value.length >= 1) {
            data = data.filter(row => filter_value.includes(row[filterAttribute]))
        }
    })

    return data
}

export const get_filtered_programacoes = (state) => {
    const filters = state.administrador.programacoes_filters
    var data = state.administrador.programacoes

    // Cruza os dados com os valores nos filtros
    Object.keys(filters).forEach(f => {
        const filter_value = filters[f]
        if (filter_value) {
            data = data.filter(d => filter_value && filter_value.length >= 1 ? filter_value.includes(d[f]) : true)
        }
    })

    return data
}

export const get_equipes_by_user = (state) => {
    const users = state.administrador.equipes_gestor

    const distinctUsers = [...new Set(users.map(u => u.usuario))]

    return distinctUsers.map(user => {
        let userRecords = users.filter(u => u.usuario == user)

        // let userGrupos = [... new Set(userRecords.map(u => u.grupo))]
        let userEquipes = [... new Set(userRecords.map(u => u.nome))]

        return { usuario_id: user, equipes: userEquipes }
    })
}

export const { setRelatorioFilter, setProgramacoesFilter, resetRelatorioFilter } = slice.actions

export default slice.reducer
