import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export const relatorio_usuario_cols = [
    {
        field: 'ano'
        , headerName: 'Ano'
        , width: '100'
        , editable: false
        , defaultVisible: false
    }
    , {
        field: 'quarter'
        , headerName: 'Quarter'
        , width: '100'
        , editable: false
        , defaultVisible: false
    }
    , {
        field: 'equipe'
        , headerName: 'Equipe'
        , width: '175'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'tipo'
        , headerName: 'Tipo de prova'
        , width: '180'
        , editable: false
        , defaultVisible: false
    }
    , {
        field: 'programacao_nome'
        , headerName: 'Certificação'
        , width: '300'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'usuario_nome'
        , headerName: 'Usuário'
        , width: '200'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'usuario_id'
        , headerName: 'E-mail'
        , width: '250'
        , editable: false
        , defaultVisible: false
    }
    , {
        field: 'nivel_usuario'
        , headerName: 'Nível'
        , width: '150'
        , editable: false
        , defaultVisible: false
    }
    , {
        field: 'data_expiracao'
        , headerName: 'Expiração'
        , width: '150'
        , editable: false
        , type: 'date'
        , valueGetter: params => params.row?.data_expiracao?._seconds ? new Date(params.row?.data_expiracao?._seconds * 1000) : null
        , defaultVisible: true
    }
    , {
        field: 'programacao_concluido'
        , headerName: 'Concluído'
        , width: 145
        , editable: false
        , type: 'boolean'
        , renderCell: params => {
            if (params.row.expirando) {
                return < ErrorIcon color='yellow' />
            } else {
                return params.row?.programacao_concluido ? <CheckIcon color='grey' /> : <ClearIcon color='lightGrey' />
            }
        }
        , defaultVisible: true
    }
    , {
        field: 'prova'
        , headerName: 'Prova'
        , width: 250
        , editable: false
        , valueGetter: params => params.row?.certificacoes.find(c => c.concluido)?.nome || null
        , defaultVisible: false
    }
    , {
        field: 'codigo_certificado'
        , headerName: 'Código'
        , width: 250
        , editable: false
        , valueGetter: params => params.row?.certificacoes.find(c => c.concluido)?.certificacao_id || null
        , defaultVisible: false
    }
    , {
        field: 'url_certificado'
        , headerName: 'URL do certificado'
        , width: 650
        , editable: false
        , valueGetter: params => params.row?.certificacoes.find(c => c.concluido)?.url_certificado || null
        , defaultVisible: false
    }
    , {
        field: 'renovacao'
        , headerName: 'Renovação'
        , width: 145
        , editable: false
        , type: 'boolean'
        , valueGetter: params => params.row?.certificacoes.find(c => c.concluido)?.renovacao || false
        , defaultVisible: false
    }
]


export const relatorio_equipes_cols = [
    {
        field: 'equipe'
        , headerName: 'Equipe'
        , width: '300'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'users'
        , headerName: 'Usuários'
        , width: '150'
        , editable: false
        , defaultVisible: true
        , valueGetter: params => [...new Set(params.row.users)].length
    }
    , {
        field: 'done'
        , headerName: 'Concluídos'
        , width: '150'
        , editable: false
        , defaultVisible: true
        , valueGetter: params => params.row.done
    }
    , {
        field: 'total'
        , headerName: 'Programados'
        , width: '150'
        , editable: false
        , defaultVisible: true
        , valueGetter: params => params.row.total
    }
    , {
        field: 'percent'
        , headerName: 'Taxa de conclusão'
        , width: '150'
        , editable: false
        , defaultVisible: true
        , valueGetter: params => params.row.done / params.row.total * 100
        , valueFormatter: params => params.value.toFixed(0) + "%"
    }
    , {
        field: 'avg'
        , headerName: 'Média por usuário'
        , width: '200'
        , editable: false
        , defaultVisible: false
        , valueGetter: params => params.row.done / [...new Set(params.row.users)].length
        , valueFormatter: params => params.value.toFixed(1)
    }

]

export const relatorio_certs_cols = [
    {
        field: 'programacao_nome'
        , headerName: 'Certificação'
        , width: '400'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'done'
        , headerName: 'Concluídos'
        , width: '150'
        , editable: false
        , defaultVisible: true
        , valueGetter: params => params.row.done
    }
    , {
        field: 'total'
        , headerName: 'Programados'
        , width: '150'
        , editable: false
        , defaultVisible: true
        , valueGetter: params => params.row.total
    }
    , {
        field: 'percent'
        , headerName: 'Taxa de conclusão'
        , width: '150'
        , editable: false
        , defaultVisible: true
        , valueGetter: params => params.row.done / params.row.total * 100
        , valueFormatter: params => params.value.toFixed(0) + "%"
    }
    , {
        field: 'users'
        , headerName: 'Usuários'
        , width: '150'
        , editable: false
        , defaultVisible: false
        , valueGetter: params => [...new Set(params.row.users)].length
    }
    , {
        field: 'avg'
        , headerName: 'Média por usuário'
        , width: '200'
        , editable: false
        , defaultVisible: false
        , valueGetter: params => params.row.done / [...new Set(params.row.users)].length
        , valueFormatter: params => params.value.toFixed(1)
    }

]

export const relatorio_users_cols = [
    {
        field: 'usuario_nome'
        , headerName: 'Usuário'
        , width: '200'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'done'
        , headerName: 'Concluídos'
        , width: '150'
        , editable: false
        , defaultVisible: true
        , valueGetter: params => params.row.done
    }
    , {
        field: 'total'
        , headerName: 'Programados'
        , width: '150'
        , editable: false
        , defaultVisible: true
        , valueGetter: params => params.row.total
    }
    , {
        field: 'percent'
        , headerName: 'Taxa de conclusão'
        , width: '150'
        , editable: false
        , defaultVisible: true
        , valueGetter: params => params.row.done / params.row.total * 100
        , valueFormatter: params => params.value.toFixed(0) + "%"
    }

]