import { url } from '../env'
import { setLogoff } from './slices/slice_user';
import store from './store';
import { getAuthByTenantId } from '../firebase/firebase';

const handleErrors = {
    GET: response => {
        if (!response.ok) {
            throw Error(response.statusText)
        }

        return response.json();
    }
    , POST: response => {
        if (!response.ok) {
            throw Error(response.statusText)
        }

        return true;
    }
    , PUT: response => {
        if (!response.ok) {
            throw Error(response.statusText)
        }

        return true;
    }
    , DELETE: response => {
        if (!response.ok) {
            throw Error(response.statusText)
        }

        return true;
    }
}

const getApi = async (params) => {
    const { endpoint, method, body, options, payload } = params
   
    const token = await getAuthByTenantId(payload?.tenantId).currentUser.getIdToken(true);

    return await fetch(`${url}${endpoint}`, {
        credentials: 'include', cache: "no-store", headers: { 'Content-Type': 'application/json', 'authorization': token, 'tenant': payload?.tenantId }
        , method, body: body ? JSON.stringify(body) : null
    })
        .then(response => {
            response?.redirected && store.dispatch(setLogoff())
            return handleErrors[method](response)
        })
        .catch(error => Promise.reject(error))
}

export default getApi