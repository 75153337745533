import { createSlice } from '@reduxjs/toolkit'
import { thunk_delete_certificacao, thunk_delete_programacao, thunk_post_certificacao, thunk_post_programacao, thunk_put_certificacao } from './slice_administrador'
import { thunk_active_user, thunk_post_certificado_colab, thunk_post_newColab, thunk_put_certificado_colab } from './slice_gestor'
import { thunk_post_certificado, thunk_put_certificado } from './slice_colaborador'

const slice = createSlice({
    name: 'errors',
    initialState: {
        errors: []
    },
    reducers: {
        fetching_message: (state) => {
            const errors = state.errors
            const text = `Salvando alterações...`
            errors.push({ text })
            return Object.assign(state, { errors })
        }
    }
    , extraReducers: builder => {
        builder
            // APIs do colaborador
            .addCase(thunk_post_certificado.rejected, (state, action) => {
                return errorHandler(state, action, 'editar certificado')
            })
            .addCase(thunk_put_certificado.rejected, (state, action) => {
                return errorHandler(state, action, 'cadastrar certificado')
            })
            // APIs do gestor
            .addCase(thunk_active_user.rejected, (state, action) => {
                return errorHandler(state, action, 'alterar status do usuário')
            })
            .addCase(thunk_post_newColab.rejected, (state, action) => {
                return errorHandler(state, action, 'incluir novo colaborador')
            })
            .addCase(thunk_put_certificado_colab.rejected, (state, action) => {
                return errorHandler(state, action, 'cadastrar certificado do colaborador')
            })
            .addCase(thunk_post_certificado_colab.rejected, (state, action) => {
                return errorHandler(state, action, 'editar certificado do colaborador')
            })
            // APIs do admin
            .addCase(thunk_put_certificacao.rejected, (state, action) => {
                return errorHandler(state, action, 'cadastrar certificação')
            })
            .addCase(thunk_post_certificacao.rejected, (state, action) => {
                return errorHandler(state, action, 'editar certificação')
            })
            .addCase(thunk_delete_certificacao.rejected, (state, action) => {
                return errorHandler(state, action, 'deletar certificação')
            })
            .addCase(thunk_post_programacao.rejected, (state, action) => {
                return errorHandler(state, action, 'cadastrar programação')
            })
            .addCase(thunk_delete_programacao.rejected, (state, action) => {
                return errorHandler(state, action, 'deletar programação')
            })
    }
})

const errorHandler = (state, action, actionName) => {
    const errors = state.errors
    const text = `Erro ao ${actionName}: ${action.error.message}`
    errors.push({ text, ...action.error })
    return Object.assign(state, { errors })
}

export const { fetching_message } = slice.actions

export default slice.reducer