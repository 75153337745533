import { useState } from 'react';

// MUI Components
import { DataGrid } from '@mui/x-data-grid';


const DataTable = (props) => {
    const { rows, columns, showConcluido, customToolbar
        , defaultSort = [{ field: 'ano', sort: 'desc' }] } = props

    // Controlled settings (pagination, columns and sorting are controlled and filters are fixed)
    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const initColumnVisibility = Object.fromEntries(columns.map(c => [c.field, c.defaultVisible]))
    const [columnVisibility, setColumnVisibility] = useState(initColumnVisibility)

    const [sortModel, setSortModel] = useState(defaultSort);

    const filterModel = { items: [{ field: 'programacao_concluido', operator: 'is', value: showConcluido ? 'true' : '' }] }

    const sx = {
        boxShadow: 2, border: 2, backgroundColor: 'white'
        , borderColor: 'white', '& .MuiDataGrid-cell:hover': { color: 'primary.main', }
    }

    return <DataGrid
        rows={rows} columns={columns}

        slots={{ toolbar: customToolbar }}

        paginationModel={paginationModel} onPaginationModelChange={setPaginationModel}

        columnVisibilityModel={columnVisibility} onColumnVisibilityModelChange={(newModel) => setColumnVisibility(newModel)}

        sortModel={sortModel} onSortModelChange={(newSortModel) => setSortModel(newSortModel)}

        filterModel={filterModel} disableColumnFilter

        pageSizeOptions={[10, 25, 50, 100]}

        localeText={{ toolbarColumns: 'Colunas', toolbarExport: 'Exportar' }}

        autoHeight sx={sx}
    />

}

export default DataTable