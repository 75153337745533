import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const Toggle = (props) => {
    const { ano, setAno } = props

    const initialYear = 2023
    const curYear = new Date().getFullYear()
    const anos = Array.from(new Array(curYear - initialYear + 1), (x, i) => i + initialYear);

    return <ToggleButtonGroup value={ano} onChange={(e, v) => setAno(v || curYear)}
        aria-label="text alignment" exclusive >
        {anos.map(a => <ToggleButton value={a} key={a} aria-label={`Ano ${a}`}>{a}</ToggleButton>)}
    </ToggleButtonGroup>
}

export default Toggle