import { createSlice } from '@reduxjs/toolkit'
import { logOff } from '../../auth/auth'

// Firebase auth
const slice = createSlice({
    name: 'user',
    initialState: { loggedIn: false, profile: {} },
    reducers: {
        setLoginStatus(state, action) {
            return Object.assign(state, { loggedIn: true, ...action.payload })
        }
        , async setLogoff(state) {
            await logOff()
            return Object.assign(state, { loggedIn: false })
        }
        , refreshToken(state, action) {
            return Object.assign(state, { tokenId: action.payload })
        }
    },
}
)

export const { setLoginStatus, setLogoff, refreshToken } = slice.actions

export default slice.reducer