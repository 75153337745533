import { Try, FormatListBulleted } from "@mui/icons-material";

/* testes feitos com os icones: Ballot, Bookmark, Info, Layers, ListAlt, PushPin, Star, SwitchLeft,ListIcon, ViewList, Try, FormatListBulleted */

const tooltips = {
    'Obrigatória': {
        tooltip: 'Consiste em uma opção de prova de realização obrigatória (afeta os indicadores da equipe).'
        , icon: <></>
    }
    , 'Opcional': {
        tooltip: 'É uma prova não-obrigatória, para realização opcional (não afeta os indicadores da equipe).'
        , icon: <Try className='info-icon grow' />
    }
    , 'Alternativas': {
        tooltip: 'Múltiplas opções de prova onde pelo menos uma deve ser concluída.'
        , icon: <FormatListBulleted className='info-icon grow' />
    }
}

export default tooltips