import React, { useState } from 'react';
import './styles.css'
import SeletorAnos from '../auxComponents/selects/years'
import { Card, CardContent, CardMedia, Divider, List, ListItem, ListItemButton, ListItemText, Stack, Typography, Paper, Skeleton } from '@mui/material';
import { useGetCronogramaQuery } from '../../store/api/colabApi';

const CronogramaWrapper = () => {

    const [ano, setAno] = useState(new Date().getFullYear())

    const { data, error, isFetching } = useGetCronogramaQuery(ano)

    return <>
        <div className='cronograma-filters'>
            {!isFetching
                ? <SeletorAnos ano={ano} setAno={setAno} />
                : <Skeleton variant='rectangular' width={60} height={55} />
            }
        </div>
        {!isFetching &&
            <>
                <br style={{ width: '100%', margin: '25px 0px' }} />
                <Cronograma data={data} loading={isFetching} />
            </>
        }
    </ >
}

const Cronograma = (props) => {
    const { data, loading } = props

    const grupos = [...new Set(data.map(p => p.grupo))]
    const quarters = [1, 2, 3, 4]

    const getProgramacoes = (quarter, grupo) => {
        const unique = [... new Set(data.filter(p => p.quarter == quarter && p.grupo == grupo).map(p => p.programacao_nome))]
        return unique.map(u => data.find(p => p.programacao_nome == u))
    }

    return <div className='content'>
        <div className='cronograma'>
            <Stack spacing={3}>
                {React.Children.toArray(quarters.map(q => {
                    return !loading 
                        ? <Paper className='quarter' elevation={1} sx={{ background: 'rgb(244,244,244)' }} >
                            <Typography sx={{ fontSize: 16, fontWeight: 600 }} className='quarter-title'>Q{q}</Typography>
                            <div className='grupos'>
                                {grupos.sort().map(grupo => {
                                    return <CronoCard quarter={q} grupo={grupo} data={getProgramacoes(q, grupo)} />
                                })}
                            </div>
                        </Paper>
                        : <Skeleton variant='square' width={'100%'} height={600} />
                }
                ))}
            </Stack>
        </div>
    </div>
}

const CronoCard = props => {
    const { quarter, grupo, data } = props

    const colors = {
        "Criação": "linear-gradient(135deg, #FFB75E, #ED8F03)"
        , "Developers & Engineers": "linear-gradient(135deg, #007FFF, #0059B2)"
        , "Data Analytics,  AdTech e CRO": "linear-gradient(135deg, #CB356B, #BD3F32)"
        , "Data Analytics, AdTech e CRO": "linear-gradient(135deg, #CB356B, #BD3F32)"
        , "Mídia": "linear-gradient(135deg, #76b852, #8DC26F)"
        , "SEO": "linear-gradient(135deg, #BE93C5, #7BC6CC)"
        , "TIM": "linear-gradient(135deg, #EF3B36, #ffff )"
        , "_default": "linear-gradient(135deg, #136a8a, #267871)"
    }

    return <Card className='card' elevation={1} >
        <CardMedia
            sx={{ height: 3, background: colors[grupo] || colors['_default'] }}
        />
        <CardContent /* style={{ background: colors[grupo] }} */>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {grupo}
            </Typography>
            <Divider />
            <List dense>
                {React.Children.toArray(data?.map(p => {
                    return <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary={p.programacao_nome} />
                        </ListItemButton>
                    </ListItem>
                }))}
            </List>
        </CardContent>
    </Card>
}

export default CronogramaWrapper;