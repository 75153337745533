// Redux
import { useSelector } from "react-redux"
import { get_equipes_by_user, get_filtered_relatorio } from "../../store/slices/slice_administrador";

// Styling

// Components
import DataTable from "../auxComponents/dataTable"
import Select from './newSelect'
import { SelectEquipes } from './newSelect'
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

// Data
import { relatorio_users_cols } from './dataDef'
import { useState } from "react";

const RelatorioPorUsuario = props => {
    const { data, loading } = props

    const equipesByUser = useSelector(state => get_equipes_by_user(state))

    const filteredData = useSelector(state => get_filtered_relatorio(state))

    const [equipesFilter, setEquipesFilter] = useState([])

    const groupedData = Object.keys(filteredData
        .reduce((acc, curValue) => {
            acc[curValue.usuario_id] = ''
            return acc
        }, {}))
        .map(usuario => {
            let usuario_nome = filteredData.find(d => d.usuario_id == usuario).usuario_nome
            let nivel_usuario = filteredData.find(d => d.usuario_id == usuario).nivel_usuario
            let total = filteredData.filter(d => d.usuario_id == usuario).length
            let done = filteredData.filter(d => d.usuario_id == usuario && d.programacao_concluido).length

            let equipesData = equipesByUser.find(u => u.usuario_id == usuario)

            return { usuario_id: usuario, usuario_nome, nivel_usuario, total, done, equipe_array: equipesData?.equipes || [] }
        })

    const filteredGroupedData = groupedData
        .filter(d => d.equipe_array.some(value => equipesFilter.length >= 1 ? equipesFilter.includes(value) : true))

    const rows = Object.keys(filteredGroupedData).map((r, i) => ({ ...filteredGroupedData[r], id: i }))

    const Filters = <>
        {/* <Select data={data} attr='ano' placeholder='Ano' loading={loading} /> */}
        <Select data={data} attr='quarter' placeholder='Quarter' loading={loading} />
        <SelectEquipes data={groupedData} attr='equipe_array' placeholder='Equipe' loading={loading} value={equipesFilter} setValue={setEquipesFilter} />
        <Select data={data} attr='tipo' placeholder='Tipo da certificação' isMulti loading={loading} />
        <Select data={data} attr='nivel_usuario' placeholder='Nível de usuário' loading={loading} />
    </>

    const CustomToolbar = () => {
        return <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarExport csvOptions={{ allColumns: true, utf8WithBom: true, delimiter: ';' }} />
        </GridToolbarContainer>
    }

    return <>
        <div className='filters'>
            <div className='selects'>
                {Filters}
            </div>
        </div>
        <br />
        <div className='table'>
            <DataTable rows={rows} columns={relatorio_users_cols} customToolbar={CustomToolbar} type={1} />
        </div>
    </>
}

export default RelatorioPorUsuario