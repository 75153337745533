import { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { thunk_get_resumo_colab } from "../../store/slices/slice_gestor";

// Components
import SeletorAnos from '../auxComponents/selects/years'
import { Dashboard } from '../colabDashboard/dashboard'

// Default components
import { Button, Skeleton, Typography } from '@mui/material'

// Icons
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

const ThirdPartyDashboardWrapper = props => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { usuario_id } = useParams()

    const tenantId = useSelector(state => state.tenant.tenantId)
    const tokenId = useSelector(state => state.user.tokenId)

    const colabData = useSelector(state => state.gestor.resumo_colab)
    const loading = useSelector(state => state.gestor.resumo_colab_status)
    const saving = useSelector(state => state.gestor.certificado_post)

    const [ano, setAno] = useState(new Date().getFullYear())

    useEffect(() => {
        loading == null && dispatch(thunk_get_resumo_colab({ usuario: usuario_id, ano, tenantId, tokenId }))
    }, [loading])

    useEffect(() => {
        dispatch(thunk_get_resumo_colab({ usuario: usuario_id, ano, tenantId, tokenId }))
    }, [ano])

    useEffect(() => {
        saving === 'done' && dispatch(thunk_get_resumo_colab({ usuario: usuario_id, ano, tenantId, tokenId }))
    }, [saving])

    return <>
        {loading == 'done' && saving == 'done'
            ? <Button style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                startIcon={<ArrowBack />}
                onClick={() => navigate(-1)}
            >Voltar</Button>
            : <Skeleton width={160} height={60} />
        }
        <br />
        {
            loading == 'done' && saving == 'done'
                ? <SeletorAnos ano={ano} setAno={setAno} />
                : <Skeleton width={60} height={70} />
        }

        <Dashboard data={colabData} firstParty={false} loading={loading} saving={saving} />
    </>
}

const GestorWarning = (props) => {
    const { usuario_nome } = props
    return <Typography variant='h6' sx={{ marginTop: '15px' }}>Você está visualizando as certificações de {usuario_nome}:</Typography>
}

export default ThirdPartyDashboardWrapper