import { Delete } from "@mui/icons-material"
import { Button } from "@mui/material"

const cols = (startDeleteFlow) => [
    {
        field: 'equipe'
        , headerName: 'Equipe'
        , width: '200'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'ano'
        , headerName: 'Ano'
        , width: '100'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'quarter'
        , headerName: 'Quarter'
        , width: '100'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'programacao_nome'
        , headerName: 'Certificações'
        , width: '400'
        , editable: false
        , defaultVisible: true
    }
    , {
        field: 'delete'
        , headerName: ''
        , width: 125
        , renderCell: params => {
            return <Button variant='contained' size='small'
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                onClick={e => startDeleteFlow(params.row)} startIcon={<Delete />} >
            </Button>
        }
    }
]

export default cols