// Redux
import { useSelector } from "react-redux"
import { get_filtered_relatorio } from "../../store/slices/slice_administrador";

// Styling

// Components
import DataTable from "../auxComponents/dataTable"
import Select from './newSelect'
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

// Data
import { relatorio_certs_cols } from './dataDef'

const Relatorio = props => {
    const { data, loading } = props

    const filteredData = useSelector(state => get_filtered_relatorio(state))

    const groupedData = filteredData
        .reduce((acc, cur) => {
            const curConcl = cur.programacao_concluido ? 1 : 0

            const done = (acc[cur.programacao_nome]?.done || 0) + curConcl
            const total = (acc[cur.programacao_nome]?.total || 0) + 1
            const users = [...(acc[cur.programacao_nome]?.users ? acc[cur.programacao_nome].users : []), cur.usuario_id]

            acc[cur.programacao_nome] = { done, total, users }
            return acc
        }, {})

    const rows = Object.keys(groupedData).map((r, i) => ({ programacao_nome: r, ...groupedData[r], id: i }))

    const Filters = <>
        {/* <Select data={data} attr='ano' placeholder='Ano' isMulti loading={loading} /> */}
        <Select data={data} attr='quarter' placeholder='Quarter' isMulti loading={loading}/>
        <Select data={data} attr='tipo' placeholder='Tipo da certificação' isMulti loading={loading} />
        <Select data={data} attr='programacao_empresa' placeholder='Grupo da certificação' isMulti loading={loading}/>
        <Select data={data} attr='equipe' placeholder='Equipe' isMulti loading={loading}/>
        <Select data={data} attr='nivel_usuario' placeholder='Nível de usuário' isMulti loading={loading}/>
    </>

    const CustomToolbar = () => {
        return <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarExport csvOptions={{ allColumns: true, utf8WithBom: true, delimiter: ';' }} />
        </GridToolbarContainer>
    }

    return <>
        <div className='filters'>
            <div className='selects'>
                {Filters}
            </div>
        </div>
        <br />
        <div className='table'>
            <DataTable rows={rows} columns={relatorio_certs_cols} customToolbar={CustomToolbar} type={1} />
        </div>
    </>
}

export default Relatorio