import { configureStore } from '@reduxjs/toolkit'

// Redux persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

// Reducer slices
import slice_colaborador from './slices/slice_colaborador'
import slice_gestor from './slices/slice_gestor'
import slice_administrador from './slices/slice_administrador'
import slice_errors from './slices/slice_errors'
import slice_user from './slices/slice_user'
import slice_tenant from './slices/slice_tenant'

import { colabApi } from './api/colabApi'

const persistConfig = {
    key: 'root',
    storage,
    version: 1
}

const store = configureStore({
    reducer: {
        colaborador: slice_colaborador,
        gestor: slice_gestor,
        administrador: slice_administrador,
        errors: slice_errors,
        user: persistReducer(persistConfig, slice_user),
        tenant: slice_tenant
        // RTK Query slices
        , [colabApi.reducerPath]: colabApi.reducer
    }
    /* , middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(beforeApiThunkMiddleware) */
    , middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(colabApi.middleware)
    , devTools: true
})

// TODO criar função de fetch e post geral para ser utilizado pelos métodos
// TODO trazer controllers para cada visão e utilizar as funções

export const persistor = persistStore(store)

export default store