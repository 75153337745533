import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { Clear } from "@mui/icons-material"

const ControlledSelect = props => {
    const { options, placeholder, value, setValue
        , type = 'single', variant = 'filled', size = 'small', clearable = true } = props

    const clear = () => {
        type == 'single' ? setValue('') : setValue([])
    }

    const isEmpty = () => {
        if (type == 'single') {
            return value == null || value == ''
        } else {
            if (Array.isArray(value)) {
                return value.length == 0
            } else {
                return false
            }
        }
    }

    const clearIcon = () => < Button sx={{ display: isEmpty() ? "none" : "", width: '10px !important', minWidth: '52px !important', height: '100%' }}
        endIcon={<Clear />} onClick={clear} />

    return <FormControl variant={variant} size={size} sx={{ width: '100%' }}>

        <InputLabel id="label">{placeholder}</InputLabel>

        <Select
            multiple={type !== 'single'}

            id={`select-${placeholder}`} labelId={`select-${placeholder}`}

            label={placeholder}

            inputProps={(!isEmpty() && clearable) ? { IconComponent: () => clearIcon() } : undefined}

            value={value} onChange={e => setValue(e.target.value)}

            renderValue={type != 'single'
                ? (selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )
                : null
            }
        >

            {options.sort().map(i => <MenuItem key={i} value={i}>{i}</MenuItem>)}

        </Select>

    </FormControl >
}

export default ControlledSelect