import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import getApi from '../api'

const slice = createSlice({
    name: 'gestor',
    initialState: {
        resumo: [], initialized: 'pending', certificado_post: 'done'
        , usuarios: [], status_usuarios: null,
        status_geral: null, status_change_ativo: 'pending'
        , resumo_colab: [], resumo_colab_status: null
    },
    reducers: {
        reset_certificado_post: (state) => {
            return Object.assign(state, { certificado_post: 'done', equipes: {} })
        }
    },
    extraReducers(builder) {
        builder
            //// GETs das abas de gestor
            // Get resumo geral
            .addCase(thunk_get_resumo_gestor.rejected, (state) => {
                return Object.assign(state, { resumo: [] }, { initialized: 'rejected', resumo_status: 'rejected' })
            })
            .addCase(thunk_get_resumo_gestor.pending, (state) => {
                return Object.assign(state, { resumo: [] }, { initialized: 'pending', resumo_status: 'pending' })
            })
            .addCase(thunk_get_resumo_gestor.fulfilled, (state, action) => {
                const status_geral = state.status_usuarios == 'done' ? { status_geral: 'done' } : {}
                return Object.assign(state, { resumo: action.payload }, { resumo_status: 'done' }, status_geral)
            })
            // Get usuários
            .addCase(thunk_get_usuarios.rejected, (state) => {
                return Object.assign(state, { usuarios: [] }, { initialized: 'rejected' })
            })
            .addCase(thunk_get_usuarios.pending, (state) => {
                return Object.assign(state, { usuarios: [] }, { initialized: 'pending' }, { status_usuarios: 'pending' })
            })
            .addCase(thunk_get_usuarios.fulfilled, (state, action) => {
                const status_geral = state.resumo_status == 'done' ? { status_geral: 'done' } : {}
                return Object.assign(state, { usuarios: action.payload }, { status_usuarios: 'done' }, status_geral)
            })
            // Get resumo de colaborador
            .addCase(thunk_get_resumo_colab.rejected, (state) => {
                return Object.assign(state, { resumo_colab: [] }, { resumo_colab_status: 'rejected' })
            })
            .addCase(thunk_get_resumo_colab.pending, (state) => {
                return Object.assign(state, { resumo_colab: [] }, { resumo_colab_status: 'pending' })
            })
            .addCase(thunk_get_resumo_colab.fulfilled, (state, action) => {
                return Object.assign(state, { resumo_colab: action.payload }, { resumo_colab_status: 'done' })
            })
            // Get equipes
            .addCase(thunk_remove_colab_from_team.fulfilled, (state) => {
                return Object.assign(state, { status_change_ativo: 'done' }, { status_usuarios: null })
            })

            // POSTS e PUTs
            .addCase(thunk_post_certificado_colab.rejected, (state) => {
                return Object.assign(state, { certificado_post: 'done' })
            })
            .addCase(thunk_post_certificado_colab.pending, (state) => {
                return Object.assign(state, { certificado_post: 'pending' })
            })
            .addCase(thunk_post_certificado_colab.fulfilled, (state) => {
                return Object.assign(state, { certificado_post: 'done' }, { status_geral: null })
            })

            .addCase(thunk_put_certificado_colab.rejected, (state) => {
                return Object.assign(state, { certificado_post: 'done' })
            })
            .addCase(thunk_put_certificado_colab.pending, (state) => {
                return Object.assign(state, { certificado_post: 'pending' })
            })
            .addCase(thunk_put_certificado_colab.fulfilled, (state) => {
                return Object.assign(state, { certificado_post: 'done' }, { status_geral: null })
            })

            .addCase(thunk_delete_certificado_colab.rejected, (state) => {
                return Object.assign(state, { certificado_post: 'done' })
            })
            .addCase(thunk_delete_certificado_colab.pending, (state) => {
                return Object.assign(state, { certificado_post: 'pending' })
            })
            .addCase(thunk_delete_certificado_colab.fulfilled, (state) => {
                return Object.assign(state, { certificado_post: 'done' }, { status_geral: null })
            })

            .addCase(thunk_post_newColab.pending, (state) => {
                return Object.assign(state, { novo_colaborador_post: 'pending' })
            })
            .addCase(thunk_post_newColab.rejected, (state) => {
                return Object.assign(state, { novo_colaborador_post: 'rejected' })
            })
            .addCase(thunk_post_newColab.fulfilled, (state) => {
                return Object.assign(state, { novo_colaborador_post: 'done' }, { status_usuarios: null })
            })

            // Remover usuario da equipe
            .addCase(thunk_active_user.pending, (state) => {
                return Object.assign(state, { status_change_ativo: null })
            })
            .addCase(thunk_active_user.rejected, (state) => {
                return Object.assign(state, { status_change_ativo: 'rejected' })
            })
            .addCase(thunk_active_user.fulfilled, (state) => {
                return Object.assign(state, { status_change_ativo: 'done' }, { status_usuarios: null })
            })

            // Ativar e inativar usuário
            .addCase(thunk_remove_colab_from_team.pending, (state) => {
                return Object.assign(state, { status_change_ativo: null })
            })
            .addCase(thunk_remove_colab_from_team.rejected, (state) => {
                return Object.assign(state, { status_change_ativo: 'rejected' })
            })
            .addCase(thunk_get_equipes.fulfilled, (state, action) => {
                return Object.assign(state, { equipes: action.payload })
            })
    }
})

//Fetch
const apiController = {
    thunk_get_resumo_gestor: (payload) => ({ endpoint: `/api/equipes/resumo?ano=${payload.ano}`, method: 'GET', payload })
    , thunk_get_usuarios: (payload) => ({ endpoint: `/api/equipes/usuarios`, method: 'GET', payload })
    , thunk_get_equipes: (payload) => ({ endpoint: `/api/equipes`, method: 'GET', payload })
    , thunk_active_user: (payload) => ({ endpoint: `/api/usuarios/${payload.usuario_id}/ativar`, method: 'POST', payload })
    , thunk_remove_colab_from_team: (payload) => ({ endpoint: `/api/equipes/${payload.equipe}/${payload.usuario}`, method: 'DELETE', payload })
    , thunk_post_newColab: (payload) => ({ endpoint: `/api/equipes/${payload.equipe}/${payload.user}`, method: 'PUT', payload })

    , thunk_get_resumo_colab: (payload) => ({ endpoint: `/api/usuarios/${payload.usuario}/resumo?ano=${payload.ano}`, method: 'GET', payload })
    , thunk_put_certificado_colab: (payload) => ({ endpoint: `/api/usuarios/${payload.user}/certificado`, method: 'PUT', body: payload.data, payload })
    , thunk_post_certificado_colab: (payload) => ({ endpoint: `/api/usuarios/${payload.user}/certificado`, method: 'POST', body: payload.data, payload })
    , thunk_delete_certificado_colab: (payload) => ({ endpoint: `/api/usuarios/${payload.user}/certificado?id=${payload.data}`, method: 'DELETE', payload })
}

export const thunk_get_resumo_gestor = createAsyncThunk('gestor/thunk_get_resumo_gestor', async (payload) => await getApi(apiController.thunk_get_resumo_gestor(payload)))
export const thunk_get_resumo_colab = createAsyncThunk('gestor/thunk_get_resumo_colab', async (payload) => await getApi(apiController.thunk_get_resumo_colab(payload)))
export const thunk_get_usuarios = createAsyncThunk('gestor/thunk_get_usuarios', async (payload) => await getApi(apiController.thunk_get_usuarios(payload)))
export const thunk_post_certificado_colab = createAsyncThunk('gestor/thunk_post_certificado_colab', async (payload) => await getApi(apiController.thunk_post_certificado_colab(payload)))
export const thunk_delete_certificado_colab = createAsyncThunk('gestor/thunk_delete_certificado_colab', async (payload) => await getApi(apiController.thunk_delete_certificado_colab(payload)))
export const thunk_put_certificado_colab = createAsyncThunk('gestor/thunk_put_certificado_colab', async (payload) => await getApi(apiController.thunk_put_certificado_colab(payload)))
export const thunk_post_newColab = createAsyncThunk('gestor/thunk_post_new_colab', async (payload) => await getApi(apiController.thunk_post_newColab(payload)))
export const thunk_active_user = createAsyncThunk('gestor/thunk_active_user', async (payload) => await getApi(apiController.thunk_active_user(payload)))
export const thunk_remove_colab_from_team = createAsyncThunk('gestor/thunk_remove_colab_from_team', async (payload) => await getApi(apiController.thunk_remove_colab_from_team(payload)))
export const thunk_get_equipes = createAsyncThunk('gestor/thunk_get_equipes', async (payload) => await getApi(apiController.thunk_get_equipes(payload)))

//Selectors
export const selector_get_gestor_resumo = (state) => {
    return state.resumo
}

export const selector_get_usuarios = (state) => {
    return state.usuarios
}

export const { reset_certificado_post } = slice.actions

export default slice.reducer